import React, { useEffect, useState, useRef } from 'react';
import { Container, Box, Grid, Typography, Button, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Document as ContentfulDocument } from '@contentful/rich-text-types';
import { CheckPricesProps } from './CheckPrices';
import CheckPricesMobile from './CheckPricesMobile';
import BannerV3Mobile from './BannerV3Mobile';
import BannerV4Mobile from './BannerV4Mobile';
import { Pagination, EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import ArrowLeft from '../../assets/arrow-left.svg';
import { BannerProps, HeroProps } from './Hero';

function HeroMobile(heroInfo: HeroProps) {
    const [startingSlide, setStartingSlide] = useState<number>(0);
    const [isSlideBeginning, setIsSlideBeginning] = useState<boolean>(true);
    const [isSlideEnd, setIsSlideEnd] = useState<boolean>(false);
    const prevRefMobile = useRef(null);
    const nextRefMobile = useRef(null);
    const swiperRef = useRef(null);
    const heroLength: number = heroInfo.banners.length;

    const slideInfo = {
        isSlideBeginning: isSlideBeginning,
        isSlideEnd: isSlideEnd,
        heroLength: heroLength,
    };

    useEffect(() => {
        if (typeof window !== undefined) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const campaignId = urlParams.get('utm_campaign');

            Object.keys(heroInfo).map((item, index) => {
                if (heroInfo[item]?.campaignId !== null) {
                    if (heroInfo[item]?.campaignId === campaignId) {
                        setStartingSlide(index);
                        swiperRef.current?.swiper.slideTo(index);
                    }
                }
            });
        }
    }, [startingSlide]);

    return (
        <Box>
            <Swiper
                ref={swiperRef}
                onInit={(swiper) => {
                    swiper.params.navigation.prevEl = prevRefMobile.current;
                    swiper.params.navigation.nextEl = nextRefMobile.current;
                    swiper.navigation.init();
                    swiper.navigation.update();
                }}
                onActiveIndexChange={({ isBeginning, isEnd }) => {
                    setIsSlideBeginning(isBeginning);
                    setIsSlideEnd(isEnd);
                }}
                pagination={{
                    el: '.hero-pagination-mobile',
                    clickable: true,
                    bulletClass: 'pagination-bullet-unique',
                    bulletActiveClass: 'pagination-bullet-unique-active',
                }}
                navigation={{
                    nextEl: '.swiper-hero-mobile-next',
                    prevEl: '.swiper-hero-mobile-prev',
                }}
                initialSlide={startingSlide}
                modules={[Pagination, Navigation, EffectFade]}
                preloadImages={true}
                lazy={false}
                grabCursor={true}
                effect={'fade'}
                className="swiperHeroMobile"
                observeParents={true}
                observer={true}
                touchRatio={0.1}
            >
                {heroInfo.banners.map((banner: BannerProps, index: number) => (
                    <>
                        <SwiperSlide key={`heroslider-${index}`}>
                            {banner.bannerBackgroundImage !== null ? (
                                <BannerV4Mobile
                                    bannerInfo={banner}
                                    slideInfo={slideInfo}
                                    partnerInfo={heroInfo.partner}
                                />
                            ) : (
                                <BannerV3Mobile
                                    bannerInfo={banner}
                                    slideInfo={slideInfo}
                                    partnerInfo={heroInfo.partner}
                                />
                            )}
                        </SwiperSlide>
                    </>
                ))}
            </Swiper>
            {/* <CheckPricesMobile checkPricesInfo={heroInfo.checkPrices} partnerInfo={heroInfo.partner} /> */}
        </Box>
    );
}

export default HeroMobile;

import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Document as ContentfulDocument } from '@contentful/rich-text-types';
import CheckPrices, { CheckPricesProps } from './CheckPrices';
import BannerV3 from './BannerV3';
import BannerV4 from './BannerV4';
import { Pagination, EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import ArrowLeft from '../../assets/arrow-left.svg';
import { ImageDataLike } from 'gatsby-plugin-image';

export interface HeroProps {
    banners: BannerProps[];
    checkPrices: CheckPricesProps;
    partner: Partner;
}

export interface Partner {
    partnerName: string;
    partnerSlug: string;
    partnerLogo: ImageDataLike;
}

export interface BannerProps {
    designVersion: string;
    campaignId: string;
    enabled: boolean;
    dateStart: Date;
    dateEnd: Date;
    bannerBackgroundImage: ImageDataLike;
    backgroundColorGradient: string;
    baseTextColor: string;
    heroImage: ImageDataLike;
    heroImageMobile: ImageDataLike;
    line1: ContentfulDocument;
    line2: ContentfulDocument;
    line3: ContentfulDocument;
    line4: ContentfulDocument;
    line1Mobile: ContentfulDocument;
    line2Mobile: ContentfulDocument;
    line3Mobile: ContentfulDocument;
    line4Mobile: ContentfulDocument;
    buttonText: string;
    buttonLink: string;
}

export interface SlideProps {
    isSlideBeginning: boolean;
    isSlideEnd: boolean;
    heroLength: number;
}

function Hero(heroInfo: HeroProps) {
    const [startingSlide, setStartingSlide] = useState<number>(0);
    const [isSlideBeginning, setIsSlideBeginning] = useState<boolean>(true);
    const [isSlideEnd, setIsSlideEnd] = useState<boolean>(false);
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const swiperRef = useRef(null);
    const heroLength: number = heroInfo.banners.length;

    const slideInfo = {
        isSlideBeginning: isSlideBeginning,
        isSlideEnd: isSlideEnd,
        heroLength: heroLength,
    };

    useEffect(() => {
        if (typeof window !== undefined) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const campaignId = urlParams.get('utm_campaign');

            Object.keys(heroInfo).map((item, index) => {
                if (heroInfo[item]?.campaignId !== null) {
                    if (heroInfo[item]?.campaignId === campaignId) {
                        setStartingSlide(index);
                        swiperRef.current?.swiper.slideTo(index);
                    }
                }
            });
        }
    }, [startingSlide]);

    return (
        <>
            <Box sx={{ height: '480px' }}>
                <Swiper
                    // install Swiper modules
                    ref={swiperRef}
                    onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                    onActiveIndexChange={({ activeIndex }) => {
                        if (activeIndex === 0) {
                            setIsSlideBeginning(true);
                        } else if (activeIndex === heroLength - 1) {
                            setIsSlideEnd(true);
                        } else {
                            setIsSlideBeginning(false);
                            setIsSlideEnd(false);
                        }
                    }}
                    pagination={{
                        el: '.hero-pagination-lg',
                        clickable: true,
                        bulletClass: 'pagination-bullet-unique',
                        bulletActiveClass: 'pagination-bullet-unique-active',
                    }}
                    navigation={{
                        nextEl: '.swiper-hero-lg-next',
                        prevEl: '.swiper-hero-lg-prev',
                    }}
                    initialSlide={startingSlide}
                    modules={[Pagination, EffectFade, Navigation]}
                    slidesPerView={-1}
                    preloadImages={true}
                    lazy={false}
                    grabCursor={true}
                    effect={'fade'}
                    shortSwipes={true}
                    edgeSwipeThreshold={10}
                    longSwipes={false}
                    // touchRatio={0.45}
                    // threshold={10}
                >
                    {heroInfo.banners.map((banner: BannerProps, index: number) => (
                        <SwiperSlide key={`heroslider-${index}`}>
                            <>
                                {banner.bannerBackgroundImage !== null ? (
                                    <BannerV4
                                        bannerInfo={banner}
                                        slideInfo={slideInfo}
                                        partnerInfo={heroInfo.partner}
                                    />
                                ) : (
                                    <BannerV3
                                        bannerInfo={banner}
                                        slideInfo={slideInfo}
                                        partnerInfo={heroInfo.partner}
                                    />
                                )}

                                <Box component="div">
                                    <Container
                                        maxWidth="lg"
                                        sx={{ height: '100%', padding: { lg: '0 !important', position: 'relative' } }}
                                    >
                                        {slideInfo.heroLength > 1 && (
                                            <Grid
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="end"
                                                position="absolute"
                                                bottom={20}
                                                item
                                                zIndex={1}
                                                sx={{}}
                                            >
                                                <Box
                                                    height="52px"
                                                    className="hero-pagination-lg"
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{
                                                        '& > span': {
                                                            mx: 1,
                                                        },
                                                    }}
                                                ></Box>
                                            </Grid>
                                        )}
                                        {slideInfo.heroLength > 1 && (
                                            <Grid
                                                display="flex"
                                                flexDirection="row"
                                                alignItems="end"
                                                justifyContent="end"
                                                position="absolute"
                                                bottom={20}
                                                right={1}
                                                zIndex={1}
                                                sx={{}}
                                                item
                                            >
                                                <Box
                                                    className="swiper-hero-lg-prev"
                                                    sx={{
                                                        cursor: 'pointer',
                                                        '& > svg > circle': {
                                                            fill: !slideInfo.isSlideBeginning && '#8223D2',
                                                        },
                                                    }}
                                                >
                                                    <ArrowLeft />
                                                </Box>
                                                <Box
                                                    className="swiper-hero-lg-next"
                                                    ml={5}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        '& > svg': {
                                                            transform: 'rotate(180deg)',
                                                            '& > circle': {
                                                                fill: !slideInfo.isSlideEnd && '#8223D2',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <ArrowLeft fill="green" />
                                                </Box>
                                            </Grid>
                                        )}
                                    </Container>
                                </Box>
                            </>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>
            {/* <CheckPrices checkPricesInfo={heroInfo.checkPrices} partnerInfo={heroInfo.partner} /> */}
        </>
    );
}

export default Hero;

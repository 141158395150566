import React, { useState } from 'react';
import {
    Container,
    Box,
    Grid,
    Typography,
    Tabs,
    Tab,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import FeaturesPanel from './FeaturesPanel';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { options } from '../../utils/Utils';
import ShieldActive from '../../assets/ShieldActive.svg';
import ShieldDefault from '../../assets/ShieldDefault.svg';
import HandshakeDefault from '../../assets/HandshakeDefault.svg';
import HandshakeActive from '../../assets/HandshakeActive.svg';
import ClipboardDefault from '../../assets/ClipboardDefault.svg';
import ClipboardActive from '../../assets/ClipboardActive.svg';
import CheckListDefault from '../../assets/CheckListDefault.svg';
import CheckListActive from '../../assets/CheckListActive.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TabContentProps, FeaturesProps, PlanInfoProps, ClaimsProps, WhyProps, PlanProps } from './TabContent';
import FeaturesPanelMobile from './FeaturesPanelMobile';
import ClaimsPanelMobile from './ClaimsPanelMobile';
import WhyPanelMobile from './WhyPanelMobile';
import PlansPanelMobile from './PlansPanelMobile';
import { features } from 'process';

const StyledTabs = styled(Tabs)({
    height: '100%',
    backgroundColor: '#671CA6',

    '& .MuiTabs-flexContainer': {
        height: '100%',
    },
    '& .Mui-selected': {
        backgroundColor: 'white',
        color: '#671CA6',
        fontWeight: 'bold',
    },
});
const StyledTab = styled(Tab)({
    border: ' 1px solid #4C147A',
    borderRadius: ' 8px 8px 0px 0px',
    color: 'white',
    height: '100%',
    justifyContent: 'start',
    fontWeight: 'bold',
});

const PanelBox = styled(Box)({
    padding: '48px 80px',
    background: '#FFFFFF',
    borderRadius: '0px 0px 8px 8px',
});

const StyledAccordion = styled(Accordion)({
    borderRadius: '16px !important',
    border: '1px solid #671CA6',

    '&:nth-of-type(1n)': {},
    '.Mui-expanded': {
        color: '#671CA6',
        background: 'none',

        '.MuiAccordionSummary-expandIconWrapper > svg': {
            fill: '#671CA6',
        },
    },
});

const StyledAccordionDetails = styled(AccordionDetails)({});

const StyledAccordionSummary = styled(AccordionSummary)({
    height: '68px',
    color: 'white',
    background: '#671CA6',
    borderRadius: '16px !important',
});

const StyledExpandMoreIcon = styled(ExpandMoreIcon)({
    fill: 'white',
});

const StyledBox = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
});

function TabContentMobile(tabContentInfo: TabContentProps) {
    const [expanded, setExpanded] = React.useState<string>('');

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : null);
    };
    return (
        <Box p={3}>
            <Grid display="flex" flexDirection="column" container spacing={3}>
                <Grid xs={12} item>
                    <StyledAccordion
                        expanded={expanded === 'panel1'}
                        disableGutters
                        elevation={0}
                        onChange={handleChange('panel1')}
                    >
                        <StyledAccordionSummary aria-controls="panel1d-content" expandIcon={<StyledExpandMoreIcon />}>
                            <StyledBox>
                                {expanded === 'panel1' ? <ShieldActive /> : <ShieldDefault />}
                                <Typography fontSize="1.125rem" lineHeight="1.5" fontWeight="700">
                                    {tabContentInfo.features.featuresTabText}
                                </Typography>
                            </StyledBox>
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                            <FeaturesPanelMobile
                                featuresInfo={tabContentInfo.features}
                                disclaimerText={tabContentInfo.tabDisclaimerText}
                            />
                        </StyledAccordionDetails>
                    </StyledAccordion>
                </Grid>
                <Grid sm={12} item>
                    <StyledAccordion
                        expanded={expanded === 'panel2'}
                        disableGutters
                        elevation={0}
                        onChange={handleChange('panel2')}
                    >
                        <StyledAccordionSummary aria-controls="panel2d-content" expandIcon={<StyledExpandMoreIcon />}>
                            <StyledBox>
                                {expanded === 'panel2' ? <HandshakeActive /> : <HandshakeDefault />}
                                <Typography fontSize="1.125rem" lineHeight="1.5" fontWeight="700">
                                    {tabContentInfo.plan.planTabText}
                                </Typography>
                            </StyledBox>
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                            {' '}
                            <PlansPanelMobile {...tabContentInfo.plan} />
                        </StyledAccordionDetails>
                    </StyledAccordion>
                </Grid>
                <Grid sm={12} item>
                    <StyledAccordion
                        expanded={expanded === 'panel3'}
                        disableGutters
                        elevation={0}
                        onChange={handleChange('panel3')}
                    >
                        <StyledAccordionSummary aria-controls="panel3d-content" expandIcon={<StyledExpandMoreIcon />}>
                            <StyledBox>
                                {expanded === 'panel3' ? <ClipboardActive /> : <ClipboardDefault />}
                                <Typography fontSize="1.125rem" lineHeight="1.5" fontWeight="700">
                                    {tabContentInfo.claims.claimsTabText}
                                </Typography>
                            </StyledBox>
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                            <ClaimsPanelMobile
                                claimsInfo={tabContentInfo.claims}
                                disclaimerText={tabContentInfo.tabDisclaimerText}
                            />
                        </StyledAccordionDetails>
                    </StyledAccordion>
                </Grid>
                {/* <Grid sm={12} item>
                    <StyledAccordion
                        expanded={expanded === 'panel4'}
                        disableGutters
                        elevation={0}
                        onChange={handleChange('panel4')}
                    >
                        <StyledAccordionSummary aria-controls="panel4d-content" expandIcon={<StyledExpandMoreIcon />}>
                            <StyledBox>
                                {expanded === 'panel4' ? <CheckListActive /> : <CheckListDefault />}
                                <Typography fontSize="1.125rem" lineHeight="1.5" fontWeight="700">
                                    {tabContentInfo.whyAsurion.whyAsurionTabText}
                                </Typography>
                            </StyledBox>
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                            <WhyPanelMobile {...tabContentInfo.whyAsurion} />
                        </StyledAccordionDetails>
                    </StyledAccordion>
                </Grid> */}
            </Grid>
        </Box>
    );
}

export default TabContentMobile;

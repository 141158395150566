import React from 'react';
import { Box, Grid, Link, Typography, Container, useMediaQuery } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTheme } from '@mui/material/styles';
import DownArrow from '../../assets/DownArrow.svg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export interface FactsProps {
    line1: string;
    line2: string;
    line3: string;
    line4: string;
    linkUrl: string;
    linkText: string;
    supportedPhoneTitle: string;
    supportedPhones: any;
    brands: any;
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

function Facts(factsInfo: FactsProps) {
    const supportedPhoneTitle =
        factsInfo && factsInfo.supportedPhoneTitle ? factsInfo.supportedPhoneTitle : 'Popular phones supported';
    const supportedPhones = factsInfo.supportedPhones.nodes;

    const grouped = groupBy(supportedPhones, (phone) => phone.brandName);
    const applePhones = grouped.get('Apple');
    const samsungPhones = grouped.get('Samsung');
    const oppoPhones = grouped.get('Oppo');
    const appleImage = getImage(factsInfo.appleImage);
    const samsungImage = getImage(factsInfo.samsungImage);
    const oppoImage = getImage(factsInfo.oppoImage);
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Box>
            <Box sx={{ background: '#671CA6', margin: 'auto', textAlign: 'center' }} p={2}>
                <Container maxWidth="xl" sx={{ height: '100%', maxWidth: '1400px !important' }}>
                    <Grid container>
                        <Typography
                            component="div"
                            fontSize="24px"
                            fontWeight="700"
                            lineHeight="30px"
                            color="#F7F1FD"
                            sx={{ margin: 'auto', textAlign: 'center' }}
                            p={4}
                        >
                            {factsInfo.line1}
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography
                            component="div"
                            fontSize="32px"
                            fontWeight="700"
                            lineHeight="40px"
                            color="#F7F1FD"
                            sx={{ margin: 'auto', textAlign: 'center' }}
                            p={1}
                        >
                            {factsInfo.line2}
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography
                            component="div"
                            fontSize="18px"
                            fontWeight="700"
                            lineHeight="22px"
                            color="#F7F1FD"
                            sx={{ margin: 'auto', textAlign: 'center', width: { xs: '100%', md: '50%' } }}
                            p={1}
                        >
                            {factsInfo.line3}
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography
                            component="div"
                            fontSize="16px"
                            fontWeight="700"
                            lineHeight="20px"
                            color="#F7F1FD"
                            sx={{ margin: 'auto', textAlign: 'center' }}
                            p={3}
                        >
                            {factsInfo.line4}
                        </Typography>
                    </Grid>
                    <Grid container>
                        {factsInfo.linkText !== '' && (
                            <Link
                                fontSize="12px"
                                fontWeight="700"
                                lineHeight="16px"
                                color="#F7F1FD"
                                sx={{ margin: 'auto', textAlign: 'center' }}
                                p={2}
                                href={factsInfo.linkUrl}
                            >
                                {factsInfo.linkText}
                            </Link>
                        )}
                    </Grid>
                </Container>
            </Box>
            <Box sx={{ background: '#ffffff' }} p={2} id="supported-phones">
                <Container maxWidth="xl" sx={{ height: '100%', maxWidth: '1400px !important' }}>
                    <Grid container>
                        <Typography
                            component="div"
                            fontWeight="700"
                            lineHeight="40px"
                            color="#671CA6"
                            sx={{ fontSize: isMobile ? '24px' : '32px', paddingTop: isMobile ? '24px' : '32px' }}
                        >
                            {supportedPhoneTitle}
                        </Typography>
                    </Grid>
                    <Grid container spacing={2} mt={2} pb={3}>
                        <Grid item xs={12} md={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <GatsbyImage image={appleImage} style={{ marginBottom: '2%' }} />
                                </Grid>
                                <Grid item xs={8}>
                                    {applePhones &&
                                        applePhones.slice(0, 3).map((b, index) => (
                                            <Box
                                                key={b.slug}
                                                mr={1}
                                                p={0.5}
                                                sx={{
                                                    fontSize: 16,
                                                    color: '#671CA6',
                                                    fontWeight: '700',
                                                }}
                                            >
                                                <Link
                                                    href={`/supported-phones/${b?.brandName.toLowerCase()}/${b?.slug}`}
                                                    sx={{ color: '#671CA6', textDecoration: 'none' }}
                                                >
                                                    {b.title}{' '}
                                                </Link>
                                            </Box>
                                        ))}
                                </Grid>
                            </Grid>
                            <Box
                                mt={4}
                                sx={{
                                    fontSize: 16,
                                    color: '#671CA6',
                                    fontWeight: '700',
                                }}
                            >
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Link href={`/supported-phones/apple`} sx={{ textDecoration: 'none' }}>
                                        {'See all iPhone models '}{' '}
                                    </Link>
                                    <ArrowForwardIcon sx={{ color: 'text.primaryv4', ml: 1 }} />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ marginTop: isMobile ? '20px' : '' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <GatsbyImage image={samsungImage} style={{ marginBottom: '2%' }} />
                                </Grid>
                                <Grid item xs={8}>
                                    {samsungPhones &&
                                        samsungPhones.slice(0, 3).map((b) => (
                                            <Box
                                                key={b.slug}
                                                mr={1}
                                                p={0.5}
                                                sx={{
                                                    fontSize: 16,
                                                    color: '#671CA6',
                                                    fontWeight: '700',
                                                }}
                                            >
                                                <Link
                                                    href={`/supported-phones/${b?.brandName.toLowerCase()}/${b?.slug}`}
                                                    sx={{ color: '#671CA6', textDecoration: 'none' }}
                                                >
                                                    {b.title}{' '}
                                                </Link>
                                            </Box>
                                        ))}
                                </Grid>
                            </Grid>
                            <Box
                                mt={4}
                                sx={{
                                    fontSize: 16,
                                    color: '#671CA6',
                                    fontWeight: '700',
                                }}
                            >
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Link href={`/supported-phones/samsung`} sx={{ textDecoration: 'none' }}>
                                        {'See all Samsung models '}{' '}
                                    </Link>
                                    <ArrowForwardIcon sx={{ color: '#8223D2', ml: 1 }} />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ marginTop: isMobile ? '20px' : '' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <GatsbyImage image={oppoImage} style={{ marginBottom: '2%' }} />
                                </Grid>
                                <Grid item xs={8}>
                                    {oppoPhones &&
                                        oppoPhones.slice(0, 3).map((b) => (
                                            <Box
                                                key={b.slug}
                                                mr={1}
                                                p={0.5}
                                                sx={{
                                                    fontSize: 16,
                                                    color: '#671CA6',
                                                    fontWeight: '700',
                                                }}
                                            >
                                                <Link
                                                    href={`/supported-phones/${b?.brandName.toLowerCase()}/${b?.slug}`}
                                                    sx={{ color: '#671CA6', textDecoration: 'none' }}
                                                >
                                                    {b.title}{' '}
                                                </Link>
                                            </Box>
                                        ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}

export default Facts;

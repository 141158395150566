import React from 'react';
import { Container, Box, Grid, Link, Typography } from '@mui/material';
import { ClaimsProps } from './TabContent';
import ContentCardNoDescription from './ContentCardNoDescription';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { options } from '../../utils/Utils';

function ClaimsPanel(claimsInfo: ClaimsProps) {
    return (
        <Box>
            <Container maxWidth="lg" sx={{ height: '100%' }}>
                <Grid display="flex" flexDirection="row" container mb={4}>
                    <Box mb={2}>
                        <Typography
                            fontSize="2rem"
                            lineHeight="39.68px"
                            color="text.primaryv4"
                            fontWeight="bold"
                            pr={20}
                            mb={1}
                        >
                            {claimsInfo.makeAClaimTitle}
                        </Typography>

                        <Typography fontSize="1.125rem" pr={3}>
                            {renderRichText(claimsInfo.makeAClaimDescription, options)}
                        </Typography>
                    </Box>
                    <Grid spacing={2} container mb={4}>
                        {claimsInfo.claimSteps.map((step) => (
                            <Grid xs={3} item>
                                <ContentCardNoDescription {...step} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                {/* <Grid display="flex" flexDirection="row" container>
                    <Box mb={2}>
                        <Typography
                            fontSize="2rem"
                            lineHeight="39.68px"
                            color="text.primaryv4"
                            fontWeight="bold"
                            pr={20}
                        >
                            {claimsInfo.applicationStepsTitle}
                        </Typography>

                        <Typography fontSize="1.125rem" pr={3}>
                            {claimsInfo.applicationStepsDescription}
                        </Typography>
                    </Box>
                    <Grid spacing={2} container>
                        {claimsInfo.applicationSteps.map((step) => (
                            <Grid xs={4} item>
                                <ContentCardNoDescription {...step} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid> */}
            </Container>
        </Box>
    );
}

export default ClaimsPanel;

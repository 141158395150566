import React from 'react';
import { Container, Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FeaturesProps } from './TabContent';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { options } from '../../utils/Utils';
import ContentCard from './ContentCard';
import ContentCardHorizontal from './ContentCardHorizontal';

function FeaturesPanelMobile({ featuresInfo, disclaimerText }) {
    const ImageBox = styled(Box)({
        height: '100%',
        background: `url(${featuresInfo.featuresImage.file.url})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    });

    return (
        <Box>
            <Grid display="flex" flexDirection="row" container mb={4}>
                <Grid xs={12} display="flex" flexDirection="column" justifyContent="center" item>
                    <Typography fontSize="2rem" lineHeight="39.68px" color="text.primaryv4" fontWeight="bold" mb={1}>
                        {featuresInfo.featuesTabHighlightTitle}
                    </Typography>
                    <Typography fontSize="1.125rem">
                        {renderRichText(featuresInfo.featuresTabHighlightDescription, options)}
                    </Typography>
                </Grid>
                <Grid xs={12} minHeight="324px" item>
                    <ImageBox />
                </Grid>
            </Grid>
            <Grid spacing={2} container mb={4}>
                {featuresInfo.featuresCards.map((feature) => (
                    <Grid md={6} xs={12} item>
                        <ContentCard {...feature} />
                    </Grid>
                ))}
            </Grid>

            <Typography fontSize="1.5rem" fontWeight="bold" color="text.primaryv4" mb={1}>
                {featuresInfo.featureMoreReasonsTitle}
            </Typography>
            <Grid spacing={2} container>
                {featuresInfo.featuresMoreReasons.map((reasons) => (
                    <Grid md={6} xs={12} position="relative" item>
                        <ContentCardHorizontal {...reasons} />
                    </Grid>
                ))}
            </Grid>
            <Box mt={3} mb={2}>
                <Typography fontSize=".75rem" color="text.disabled" sx={{ '& > p > a': { color: 'text.disabled' } }}>
                    {renderRichText(disclaimerText, options)}
                </Typography>
            </Box>
        </Box>
    );
}

export default FeaturesPanelMobile;

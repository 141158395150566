import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { TypeD2cGenericArticleCardFields } from '../../types/my-types';
import { styled } from '@mui/material/styles';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { options } from '../../utils/Utils';
import InfoIcon from '../../assets/infoicon.svg';

const Outerbox = styled(Grid)({
    height: '148px',
    background: '#FFFFFF',
    border: '1px solid #F2F2F2',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.06)',
    borderRadius: '8px',
    position: 'relative',
});
const ImageBox = styled(Box)({
    height: '100%',
});
const ContentBox = styled(Grid)({
    height: '100%',
});

const CardTooltip = styled(Box)({
    background: '#ECDEFD',
    padding: '12px',
    boxShadow: '4px 4px 16px rgba(17, 17, 17, 0.08)',
    borderRadius: '8px',
    position: 'absolute',
    top: '50%',
    zIndex: 1,
});

export const ToolTipBox = styled(Box)({
    '& > svg:hover': {
        cursor: 'pointer',
    },
});

function ContentCardNoDescriptionHorizontal(cardInfo: TypeD2cGenericArticleCardFields) {
    const [isShown, setIsShown] = useState<boolean>(false);

    const toggleTooltip = () => {
        setIsShown(isShown ? false : true);
    };

    return (
        <Outerbox display="flex" flexDirection="row" container>
            <Grid xs={6} item>
                <ImageBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ background: cardInfo.thumbnailBackgroundColor }}
                    p={1}
                >
                    <img src={cardInfo.thumbnail.file.url} alt={cardInfo.thumbnail.file.fileName} height="120px" />
                </ImageBox>
            </Grid>
            <Grid xs={6} item>
                <ContentBox display="flex" flexDirection="row" justifyContent="space-between" p={2} container>
                    <Grid xs={9} item>
                        <Typography fontSize="1.125rem" fontWeight="bold" lineHeight="22.32px" color="text.primaryv4">
                            {cardInfo.title}
                        </Typography>
                    </Grid>
                    <Grid xs={3} display="flex" flexDirection="row" justifyContent="end" item>
                        {cardInfo.featureText && (
                            <ToolTipBox>
                                <InfoIcon onClick={toggleTooltip} />
                            </ToolTipBox>
                        )}
                    </Grid>
                </ContentBox>
            </Grid>
            {cardInfo.featureText && isShown && (
                <CardTooltip
                    sx={{
                        right: {
                            xs: '25%',
                            sm: '50%',
                        },
                    }}
                >
                    <Typography fontSize=".75rem" color="primary" lineHeight="22.32px">
                        {cardInfo.featureText}
                    </Typography>
                </CardTooltip>
            )}
        </Outerbox>
    );
}

export default ContentCardNoDescriptionHorizontal;

import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { TypeD2cGenericArticleCardFields } from '../../types/my-types';
import { styled } from '@mui/material/styles';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { options } from '../../utils/Utils';
import InfoIcon from '../../assets/infoicon.svg';

const Outerbox = styled(Box)({
    height: '320px',
    background: '#FFFFFF',
    border: '1px solid #F2F2F2',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.06)',
    borderRadius: '8px',
});
const ImageBox = styled(Box)({
    height: '140px',
});
const ContentBox = styled(Box)({
    height: '50%',
});

const CardTooltip = styled(Box)({
    background: '#ECDEFD',
    padding: '12px',
    boxShadow: '4px 4px 16px rgba(17, 17, 17, 0.08)',
    borderRadius: '8px',
    position: 'absolute',
    top: '40%',
    zIndex: 1,
});

export const ToolTipBox = styled(Box)({
    '& > svg:hover': {
        cursor: 'pointer',
    },
});

function ContentCard(cardInfo: TypeD2cGenericArticleCardFields) {
    const [isShown, setIsShown] = useState<boolean>(false);

    const toggleTooltip = () => {
        setIsShown(isShown ? false : true);
    };

    return (
        <Outerbox>
            <ImageBox
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                sx={{ background: cardInfo.thumbnailBackgroundColor }}
                p={1}
            >
                <img src={cardInfo.thumbnail.file.url} alt={cardInfo.thumbnail.file.fileName} height="80px" />
            </ImageBox>

            <ContentBox p={2}>
                <Box display="flex" flexDirection="row">
                    <Typography
                        fontSize="1.125rem"
                        fontWeight="bold"
                        lineHeight="22.32px"
                        color="text.primaryv4"
                        mb={0.5}
                    >
                        {cardInfo.title}
                    </Typography>
                    {cardInfo.featureText && (
                        <ToolTipBox>
                            <InfoIcon onClick={toggleTooltip} />
                        </ToolTipBox>
                    )}
                </Box>
                {cardInfo.description && (
                    <Typography fontSize="1rem" lineHeight="22.32px">
                        {renderRichText(cardInfo.description, options)}
                    </Typography>
                )}
            </ContentBox>
            {cardInfo.featureText && isShown && (
                <CardTooltip
                    sx={{
                        right: {
                            xs: '25%',
                            sm: ' 15%',
                            md: '10%',
                            lg: '-10%',
                        },
                    }}
                >
                    <Typography fontSize=".75rem" color="primary" lineHeight="22.32px">
                        {cardInfo.featureText}
                    </Typography>
                </CardTooltip>
            )}
        </Outerbox>
    );
}

export default ContentCard;

import React, { useState } from 'react';
import { Box, Card, Typography, Button } from '@mui/material';
import { TypeD2cGenericArticleCardFields } from '../../types/my-types';
import { styled } from '@mui/material/styles';
import InfoIcon from '../../assets/infoicon.svg';
import { ToolTipBox } from './ContentCard';

const Outerbox = styled(Box)({
    minHeight: '228px',
    background: '#FFFFFF',
    border: '1px solid #F2F2F2',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.06)',
    borderRadius: '8px',
    position: 'relative',
});
const ImageBox = styled(Box)({
    height: '140px',
});
const ContentBox = styled(Box)({
    height: '50%',
});

const CardTooltip = styled(Box)({
    background: '#ECDEFD',
    padding: '12px',
    boxShadow: '4px 4px 16px rgba(17, 17, 17, 0.08)',
    borderRadius: '8px',
    position: 'absolute',
    top: '40%',
    right: '-10%',
    zIndex: 1,
});

const TagText = styled(Card)({
    position: 'absolute',
    padding: '8px 12px',
    top: '10px',
    left: '10px',
    '& :hover': {
        cursor: 'default',
    },
});

const TagBox = styled(Box)({
    position: 'absolute',
    left: '8px',
    top: '8px',
});

function ContentCardNoDescription(cardInfo: TypeD2cGenericArticleCardFields) {
    const [isShown, setIsShown] = useState<boolean>(false);

    const toggleTooltip = () => {
        setIsShown(isShown ? false : true);
    };
    return (
        <Outerbox>
            <ImageBox
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                sx={{ background: cardInfo.thumbnailBackgroundColor }}
                p={1}
            >
                <img src={cardInfo.thumbnail.file.url} alt={cardInfo.thumbnail.file.fileName} height="100px" />
            </ImageBox>
            {cardInfo.tags &&
                cardInfo.tags.map((tag, index) => (
                    <TagBox key={`tag-${index}`}>
                        <Button
                            size="medium"
                            variant="contained"
                            sx={{ backgroundColor: 'text.primaryv4', cursor: 'pointer' }}
                        >
                            {tag}
                        </Button>
                    </TagBox>
                ))}
            <ContentBox p={2}>
                <Box display="flex" flexDirection="row">
                    <Typography
                        fontSize="1.125rem"
                        fontWeight="bold"
                        color="text.primaryv4"
                        mb={0.5}
                        lineHeight="22.32px"
                    >
                        {cardInfo.title}
                    </Typography>
                    {cardInfo.featureText && (
                        <ToolTipBox>
                            <InfoIcon onClick={toggleTooltip} />
                        </ToolTipBox>
                    )}
                </Box>
            </ContentBox>
            {cardInfo.featureText && isShown && (
                <CardTooltip
                    sx={{
                        right: {
                            xs: '25%',
                            sm: ' 15%',
                            md: '10%',
                            lg: '-10%',
                        },
                    }}
                >
                    <Typography fontSize=".75rem" color="primary" lineHeight="22.32px">
                        {cardInfo.featureText}
                    </Typography>
                </CardTooltip>
            )}
            {cardInfo.tags &&
                isShown &&
                cardInfo.tags.map((tag) => (
                    <TagText key={`tag-${index}`} sx={{ backgroundColor: 'text.primaryv4' }}>
                        <Typography color="text.white">{tag}</Typography>
                    </TagText>
                ))}
        </Outerbox>
    );
}

export default ContentCardNoDescription;

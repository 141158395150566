import React, { useState, useRef } from 'react';
import { BannerProps } from './Hero';
import { Box, Container, Grid, Typography, Button, Link, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { getImage } from 'gatsby-plugin-image';
import { redirectWithParams } from '../../utils/Utils';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import parse from 'html-react-parser';

import CurvedArrowWhite from '../../assets/hero-curved-arrow-white.svg';
import CurvedArrowBlack from '../../assets/hero-curved-arrow-black.svg';
import ArrowLeft from '../../assets/arrow-left.svg';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

export interface SlideProps {
    isSlideBeginning: boolean;
    isSlideEnd: boolean;
    heroLength: number;
}

function BannerV3Mobile({ bannerInfo, slideInfo, partnerInfo }) {
    const theme = useTheme();
    const banner: BannerProps = bannerInfo;
    const isXtraSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const Bold = ({ children }) => (
        <Typography component="span" fontWeight="bold">
            {children}
        </Typography>
    );
    const Italic = ({ children }) => (
        <Typography component="span" fontStyle="italic" color="text.secondary">
            {children}
        </Typography>
    );
    const Text = ({ children }) => <Typography className="">{children}</Typography>;

    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
            [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
            [MARKS.CODE]: (text) => parse(`${text}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) =>
                node.content.some(
                    (childNode) =>
                        childNode.nodeType === `text` && childNode.marks.some((mark) => mark.type === MARKS.CODE)
                ) ? (
                    children
                ) : (
                    <Text>{children}</Text>
                ),
        },
    };

    return (
        <Box
            sx={{
                height: {
                    xs: '582px',
                },
            }}
        >
            <Box
                height="100%"
                sx={{
                    background: `${banner.backgroundColorGradient}`,
                }}
            >
                {/* <BackgroundImage
                    {...convertToBgImage(getImage(banner.heroImageMobile))}
                    preserveStackingContext
                    style={{
                        height: '100%',
                        zIndex: 1,
                        backgroundSize: 'contain',
                        backgroundPosition: ' 10% bottom',
                    }}
                    loading="eager"
                > */}
                {partnerInfo && (
                    <Box
                        pt={5}
                        sx={{
                            width: '100%',
                            background: 'linear-gradient(180deg, rgba(52, 14, 83, 0.8) 0%, rgba(52, 14, 83, 0) 100%);',
                            px: {
                                sm: '24px',
                                xs: '16px',
                            },
                        }}
                    >
                        <Box
                            component="img"
                            src={partnerInfo.partnerLogo.file.url}
                            alt="partner-logo"
                            sx={{ width: 'inherit', maxWidth: '312.2px', height: '24px' }}
                        />
                    </Box>
                )}
                <Container maxWidth="lg" sx={{ height: '50%' }}>
                    <Grid
                        height="100%"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        container
                        sx={{
                            color: `${banner.baseTextColor} !important`,
                        }}
                    >
                        <Grid item>
                            {banner.line1Mobile && (
                                <Box
                                    mb={1}
                                    pt={partnerInfo === null ? 5 : 2}
                                    sx={{
                                        '& > p': {
                                            margin: 0,
                                        },
                                    }}
                                >
                                    {renderRichText(banner.line1Mobile, options)}
                                </Box>
                            )}

                            {banner.line2Mobile && (
                                <Box
                                    sx={{
                                        '& > p': {
                                            margin: 0,
                                        },
                                    }}
                                >
                                    {renderRichText(banner.line2Mobile, options)}
                                </Box>
                            )}

                            {banner.line3Mobile && (
                                <Box
                                    sx={{
                                        '& > p': {
                                            margin: 0,
                                        },
                                    }}
                                >
                                    {renderRichText(banner.line3Mobile, options)}
                                </Box>
                            )}

                            <Grid display="flex" flexDirection="row" pt={3} container>
                                <Grid xs={9} sm={5} md={4} display="flex" flexDirection="column" item>
                                    {banner.line4Mobile && (
                                        <Typography
                                            sx={{
                                                '& > p': {
                                                    margin: 0,
                                                },
                                            }}
                                        >
                                            {renderRichText(banner.line4Mobile, options)}
                                        </Typography>
                                    )}
                                    <Button
                                        component={Link}
                                        href={banner.buttonLink}
                                        color="white"
                                        variant="contained"
                                        size="medium"
                                        sx={{
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                            color: 'text.primaryv4',
                                            textTransform: 'none',
                                            mt: 1,
                                            textAlign: 'center',
                                        }}
                                        id="btn-home-phone-eligibility"
                                    >
                                        {banner.buttonText}
                                    </Button>
                                </Grid>
                                <Grid
                                    xs={3}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="start"
                                    justifyContent="center"
                                    pl={1}
                                    item
                                >
                                    {banner.baseTextColor === 'black' ? <CurvedArrowBlack /> : <CurvedArrowWhite />}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <Grid height="50%" item>
                    <BackgroundImage
                        {...convertToBgImage(getImage(banner.heroImageMobile))}
                        preserveStackingContext
                        style={{
                            height: '100%',
                            width: '100%',
                            zIndex: 1,
                            backgroundSize: isXtraSmall ? 'contain' : 'cover',
                            backgroundPosition: isXtraSmall ? 'bottom' : 'top center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'end',
                        }}
                        loading="eager"
                    >
                        {slideInfo.heroLength > 1 && (
                            <Grid
                                width="100%"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignSelf="end"
                                px={2}
                                item
                            >
                                <Box
                                    className="swiper-hero-mobile-prev"
                                    sx={{
                                        '& > svg > circle': {
                                            fill: !slideInfo.isSlideBeginning && '#8223D2',
                                        },
                                    }}
                                >
                                    <ArrowLeft />
                                </Box>
                                <Box>
                                    <Box
                                        height="52px"
                                        className="hero-pagination-mobile"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                            '& > span': {
                                                mx: 1,
                                            },
                                        }}
                                    />
                                </Box>

                                <Box
                                    className="swiper-hero-mobile-next"
                                    sx={{
                                        '& > svg': {
                                            transform: 'rotate(180deg)',
                                            '& > circle': { fill: !slideInfo.isSlideEnd && '#8223D2' },
                                        },
                                    }}
                                >
                                    <ArrowLeft />
                                </Box>
                            </Grid>
                        )}
                    </BackgroundImage>
                </Grid>
                {/* </BackgroundImage> */}
            </Box>
        </Box>
        // <>
        //     {isSmall ? (
        //         <Grid
        //             height="100%"
        //             display="flex"
        //             flexDirection="column"
        //             sx={{
        //                 background: `linear-gradient(${bannerInfo.backgroundColorGradient}), #FFFFFF`,
        //                 position: 'relative',
        //                 height: {
        //                     xs: '532px',
        //                     // sm: '704px',
        //                 },
        //             }}
        //             container
        //         >
        //             <Grid height="40%" display="flex" flexDirection="row" id="top" item>
        //                 <BackgroundImage
        //                     {...convertToBgImage(getImage(bannerInfo.heroImageMobile))}
        //                     preserveStackingContext
        //                     style={{
        //                         height: '100%',
        //                         width: '100%',
        //                         zIndex: 1,
        //                         backgroundSize: 'cover',
        //                         backgroundPosition: 'left',
        //                     }}
        //                     loading="eager"
        //                 ></BackgroundImage>
        //             </Grid>
        //             <Grid
        //                 height="50%"
        //                 display="flex"
        //                 flexDirection="column"
        //                 justifyContent="center"
        //                 px={2}
        //                 item
        //                 sx={{
        //                     color: `${bannerInfo.baseTextColor} !important`,
        //                 }}
        //                 id="bottom"
        //             >
        //                 {bannerInfo.line1Mobile !== null && (
        //                     <Box mb={1}>{renderRichText(bannerInfo.line1Mobile, options)}</Box>
        //                 )}
        //                 {bannerInfo.line2Mobile !== null && (
        //                     <Box mb={1}>{renderRichText(bannerInfo.line2Mobile, options)}</Box>
        //                 )}
        //                 {bannerInfo.line3Mobile !== null && (
        //                     <Box mb={1}>{renderRichText(bannerInfo.line3Mobile, options)}</Box>
        //                 )}
        //                 {bannerInfo.buttonText !== null && bannerInfo.buttonLink !== null && (
        //                     <Box mb={2}>
        //                         <Button
        //                             component={MuiLink}
        //                             href={redirectWithParams(bannerInfo.buttonLink)}
        //                             color="primary"
        //                             variant="contained"
        //                             width="100%"
        //                             size="large"
        //                             sx={{
        //                                 textTransform: 'none',
        //                                 fontSize: '1.25rem',
        //                             }}
        //                             id={
        //                                 bannerInfo.campaignId
        //                                     ? 'btn-home-' + bannerInfo.campaignId
        //                                     : 'btn-home-phone-eligibility'
        //                             }
        //                         >
        //                             {bannerInfo.buttonText}
        //                         </Button>
        //                     </Box>
        //                 )}
        //                 {bannerInfo.line4 !== null && <Box>{renderRichText(bannerInfo.line4Mobile, options)}</Box>}
        //             </Grid>
        //         </Grid>
        //     ) : (
        //         <Box component="div" height={'474px'} sx={{ background: '#FFFFFF' }}>
        //             <Grid
        //                 height="100%"
        //                 display="flex"
        //                 flexDirection="row"
        //                 justifyContent="end"
        //                 sx={{
        //                     background: `linear-gradient(${bannerInfo.backgroundColorGradient})`,
        //                     position: 'relative',
        //                 }}
        //                 container
        //                 id="mobile containter"
        //             >
        //                 <Grid
        //                     height="100%"
        //                     display="flex"
        //                     flexDirection="row"
        //                     xs={5}
        //                     sx={{ zIndex: 1 }}
        //                     id="bottom-layer"
        //                     item
        //                 >
        //                     <Container
        //                         maxWidth="lg"
        //                         sx={{
        //                             position: 'relative',
        //                             pr: '0 !important',
        //                         }}
        //                     >
        //                         <Grid
        //                             height="100%"
        //                             display="flex"
        //                             flexDirection="column"
        //                             alignItems="start"
        //                             justifyContent="center"
        //                             sx={{
        //                                 color: `${bannerInfo.baseTextColor} !important`,
        //                             }}
        //                             item
        //                         >
        //                             {bannerInfo.line1Mobile !== null && (
        //                                 <Box mb={1}>{renderRichText(bannerInfo.line1Mobile, options)}</Box>
        //                             )}
        //                             {bannerInfo.line2Mobile !== null && (
        //                                 <Box mb={1}>{renderRichText(bannerInfo.line2Mobile, options)}</Box>
        //                             )}
        //                             {bannerInfo.line3Mobile !== null && (
        //                                 <Box mb={1}>{renderRichText(bannerInfo.line3Mobile, options)}</Box>
        //                             )}
        //                             {bannerInfo.buttonText !== null && bannerInfo.buttonLink !== null && (
        //                                 <Box mb={2}>
        //                                     <Button
        //                                         component={MuiLink}
        //                                         href={redirectWithParams(bannerInfo.buttonLink)}
        //                                         color="primary"
        //                                         variant="contained"
        //                                         size="large"
        //                                         id={
        //                                             bannerInfo.campaignId
        //                                                 ? 'btn-home-' + bannerInfo.campaignId
        //                                                 : 'btn-home-phone-eligibility'
        //                                         }
        //                                         sx={{ textTransform: 'none' }}
        //                                     >
        //                                         {bannerInfo.buttonText}
        //                                     </Button>
        //                                 </Box>
        //                             )}
        //                             {bannerInfo.line4 !== null && (
        //                                 <Box>{renderRichText(bannerInfo.line4Mobile, options)}</Box>
        //                             )}
        //                             {/* {slideInfo.heroLength > 1 && (
        //                                 <Grid
        //                                     display="flex"
        //                                     flexDirection="column"
        //                                     justifyContent="center"
        //                                     position="absolute"
        //                                     bottom={20}
        //                                     item
        //                                 >
        //                                     <Box
        //                                         height="52px"
        //                                         className="hero-pagination"
        //                                         display="flex"
        //                                         justifyContent="center"
        //                                         alignItems="center"
        //                                         sx={{
        //                                             '& > span': {
        //                                                 mx: 1,
        //                                             },
        //                                         }}
        //                                     ></Box>
        //                                 </Grid>
        //                             )} */}
        //                         </Grid>
        //                     </Container>
        //                 </Grid>
        //                 <Grid
        //                     xs={7}
        //                     height="100%"
        //                     display="flex"
        //                     flexDirection="row"
        //                     justifyContent="end"
        //                     id="top-layer"
        //                     container
        //                 >
        //                     <BackgroundImage
        //                         {...convertToBgImage(getImage(bannerInfo.heroImageTablet))}
        //                         preserveStackingContext
        //                         style={{
        //                             height: '100%',
        //                             width: '100%',
        //                             zIndex: 1,
        //                             backgroundSize: 'cover',
        //                             backgroundPosition: 'left',
        //                         }}
        //                         loading="eager"
        //                     >
        //                         {/* {slideInfo.heroLength > 1 && (
        //                             <Grid
        //                                 display="flex"
        //                                 flexDirection="row"
        //                                 alignItems="end"
        //                                 justifyContent="end"
        //                                 position="absolute"
        //                                 bottom={20}
        //                                 right={1}
        //                                 pr={10}
        //                                 item
        //                             >
        //                                 <Box
        //                                     className="swiper-hero-prev"
        //                                     sx={{
        //                                         cursor: 'pointer',
        //                                         '& > svg > circle': {
        //                                             fill: !slideInfo.isSlideBeginning && '#8223D2',
        //                                         },
        //                                     }}
        //                                 >
        //                                     <ArrowLeft />
        //                                 </Box>
        //                                 <Box
        //                                     className="swiper-hero-next"
        //                                     ml={5}
        //                                     sx={{
        //                                         cursor: 'pointer',
        //                                         '& > svg': {
        //                                             transform: 'rotate(180deg)',
        //                                             '& > circle': {
        //                                                 fill: !slideInfo.isSlideEnd && '#8223D2',
        //                                             },
        //                                         },
        //                                     }}
        //                                 >
        //                                     <ArrowLeft fill="green" />
        //                                 </Box>
        //                             </Grid>
        //                         )} */}
        //                     </BackgroundImage>
        //                 </Grid>
        //             </Grid>
        //         </Box>
        //     )}
        // </>
    );
}

export default BannerV3Mobile;

import React from 'react';
import { Container, Box, Grid, Typography, Button, Link, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';
import { getImage, GatsbyImage, ImageDataLike } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { redirectWithParams } from '../../utils/Utils';
import CurvedArrowWhite from '../../assets/hero-curved-arrow-white.svg';
import CurvedArrowBlack from '../../assets/hero-curved-arrow-black.svg';
import { BannerProps } from './Hero';
import { useTheme } from '@mui/material/styles';
import ArrowLeft from '../../assets/arrow-left.svg';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import parse from 'html-react-parser';

function BannerV4Mobile({ bannerInfo, slideInfo, partnerInfo }) {
    const banner: BannerProps = bannerInfo;
    const Bold = ({ children }) => (
        <Typography component="span" fontWeight="bold">
            {children}
        </Typography>
    );
    const Italic = ({ children }) => (
        <Typography component="span" fontStyle="italic" color="text.secondary">
            {children}
        </Typography>
    );
    const Text = ({ children }) => <Typography className="">{children}</Typography>;

    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
            [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
            [MARKS.CODE]: (text) => parse(`${text}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) =>
                node.content.some(
                    (childNode) =>
                        childNode.nodeType === `text` && childNode.marks.some((mark) => mark.type === MARKS.CODE)
                ) ? (
                    children
                ) : (
                    <Text>{children}</Text>
                ),
        },
    };

    return (
        <Box
            sx={{
                height: {
                    xs: partnerInfo ? '606px' : '582px',
                },
            }}
        >
            <BackgroundImage
                {...convertToBgImage(getImage(banner.bannerBackgroundImage))}
                preserveStackingContext
                style={{
                    height: '100%',
                    zIndex: 1,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
                loading="eager"
            >
                {/* <BackgroundImage
                    {...convertToBgImage(getImage(banner.heroImageMobile))}
                    preserveStackingContext
                    style={{
                        height: '100%',
                        zIndex: 1,
                        backgroundSize: '100%',
                        backgroundPosition: 'bottom',
                    }}
                    loading="eager"
                > */}
                {partnerInfo && (
                    <Box
                        pt={5}
                        sx={{
                            width: '100%',
                            background: 'linear-gradient(180deg, rgba(52, 14, 83, 0.8) 0%, rgba(52, 14, 83, 0) 100%);',
                            px: {
                                sm: '24px',
                                xs: '16px',
                            },
                            position: 'absolute',
                        }}
                    >
                        {/* <Box
                            component="img"
                            src={partnerInfo.partnerLogo.file.url}
                            alt="partner-logo"
                            sx={{ width: 'inherit', maxWidth: '312.2px', height: '24px' }}
                        /> */}
                    </Box>
                )}
                <Container maxWidth="lg" sx={{ height: '50%' }}>
                    <Grid
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        container
                        sx={{
                            color: `${banner.baseTextColor} !important`,
                        }}
                    >
                        <Grid xs={12} item>
                            {partnerInfo && (
                                <Box width="100%" pt={5}>
                                    <Box
                                        component="img"
                                        src={partnerInfo.partnerLogo.file.url}
                                        alt="partner-logo"
                                        sx={{ width: 'inherit', maxWidth: '312.2px', height: '24px' }}
                                    />
                                </Box>
                            )}
                            {banner.line1 && (
                                <Typography
                                    variant="h1"
                                    mb={1}
                                    pt={partnerInfo === null ? 5 : 2}
                                    sx={{
                                        '& >p': {
                                            fontSize: '2rem',
                                            color: 'text.white',
                                            lineHeight: '38.4px',
                                            fontWeight: 'bold',
                                        },
                                    }}
                                >
                                    {renderRichText(banner.line1, options)}
                                </Typography>
                            )}

                            {banner.line2 && (
                                <Box
                                    sx={{
                                        '& > p': {
                                            fontSize: '1.125rem',
                                            lineHeight: '22.32px',
                                            color: 'text.white',
                                        },
                                    }}
                                >
                                    {renderRichText(banner.line2, options)}
                                </Box>
                            )}

                            {banner.line3 && (
                                <Box
                                    sx={{
                                        '& > p': {
                                            fontSize: '1.125rem',
                                            lineHeight: '22.32px',
                                            color: 'text.white',
                                        },
                                    }}
                                >
                                    {renderRichText(banner.line2, options)}
                                </Box>
                            )}

                            <Grid display="flex" flexDirection="row" justifyContent="start" pt={3} container>
                                <Grid xs={9} sm={5} md={4} item>
                                    {banner.line4 && (
                                        <Box
                                            sx={{
                                                '& >p': {
                                                    fontSize: '0.875rem',
                                                    color: '#FBE83B',
                                                    fontWeight: '700',
                                                },
                                            }}
                                        >
                                            {renderRichText(banner.line4, options)}
                                        </Box>
                                    )}

                                    <Button
                                        component={Link}
                                        href={banner.buttonLink}
                                        color="white"
                                        variant="contained"
                                        size="medium"
                                        sx={{
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                            color: 'text.primaryv4',
                                            textTransform: 'none',
                                            mt: 1,
                                            textAlign: 'center',
                                            width: '100%',
                                        }}
                                        id="btn-home-phone-eligibility"
                                    >
                                        {banner.buttonText}
                                    </Button>
                                </Grid>
                                <Grid
                                    xs={3}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="start"
                                    justifyContent="center"
                                    pl={1}
                                    item
                                >
                                    {banner.baseTextColor === 'black' ? <CurvedArrowBlack /> : <CurvedArrowWhite />}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <Grid height="50%" width="100%" container>
                    <BackgroundImage
                        {...convertToBgImage(getImage(banner.heroImageMobile))}
                        preserveStackingContext
                        style={{
                            height: '100%',
                            width: '100%',
                            zIndex: 1,
                            backgroundSize: 'contain',
                            backgroundPosition: 'bottom',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'end',
                        }}
                        loading="eager"
                    >
                        {slideInfo.heroLength > 1 && (
                            <Grid
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                justifySelf="end"
                                item
                                px={2}
                            >
                                <Box
                                    className="swiper-hero-mobile-prev"
                                    sx={{
                                        '& > svg > circle': {
                                            fill: !slideInfo.isSlideBeginning && '#8223D2',
                                        },
                                    }}
                                >
                                    <ArrowLeft />
                                </Box>
                                <Box>
                                    <Box
                                        height="52px"
                                        className="hero-pagination-mobile"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                            '& > span': {
                                                mx: 1,
                                            },
                                        }}
                                    />
                                </Box>

                                <Box
                                    className="swiper-hero-mobile-next"
                                    sx={{
                                        '& > svg': {
                                            transform: 'rotate(180deg)',
                                            '& > circle': { fill: !slideInfo.isSlideEnd && '#8223D2' },
                                        },
                                    }}
                                >
                                    <ArrowLeft />
                                </Box>
                            </Grid>
                        )}
                    </BackgroundImage>
                </Grid>
                {/* </BackgroundImage> */}
            </BackgroundImage>
        </Box>
    );
}

export default BannerV4Mobile;

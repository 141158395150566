import React, { useRef, useState } from 'react';
import { Grid, Box, Typography, Link } from '@mui/material';
import { PlanInfoProps, PlanProps } from './TabContent';
import { styled } from '@mui/material/styles';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { options, redirectWithParams } from '../../utils/Utils';
import CheckIcon from '../../assets/checksvg.svg';
import CrossIcon from '../../assets/crosssvg.svg';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation, Controller } from 'swiper';

const TableContainer = styled(Box)({
    filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.12))',
});

const SwiperPanel = styled(Swiper)({
    '& .swiper-button-next': {
        color: '#000',
        top: '3%',
        '&:after': {
            fontSize: '20px',
            fontWeight: 'bold',
        },
    },
    '& .swiper-button-prev': {
        color: '#000',
        top: '3%',
        '&:after': {
            fontSize: '20px',
            fontWeight: 'bold',
        },
    },
});
const PlanTile = styled(Grid)({
    padding: '24px 16px',
    '& div > p': {
        fontSize: '1rem',
    },
    '& div > p > span': {
        fontSize: '1.25rem !important',
        lineHeight: '1',
    },
});

const TopTile = styled(PlanTile)({
    textAlign: 'center',
    height: '92px',
    fontSize: '16px',
});

const BottomTile = styled(PlanTile)({
    textAlign: 'center',
    '&:first-child': {
        borderBottomLeftRadius: '8px',
    },
    '&:last-child': {
        borderBottomRightRadius: '8px',
    },
});

const MediumBox = styled(Box)({
    height: '100px',
});

const LargeBox = styled(Box)({
    height: '150px',
});

const displayText = (value: any) => {
    if (value === null) {
        return <Typography textAlign="center">-</Typography>;
    } else if (typeof value === 'string') {
        return <Typography>{value}</Typography>;
    } else if (typeof value === 'boolean') {
        if (value) {
            return (
                <Box textAlign="center">
                    <CheckIcon />
                </Box>
            );
        } else {
            return (
                <Box textAlign="center">
                    <CrossIcon />
                </Box>
            );
        }
    } else {
        return <Box mb={1}>{renderRichText(value, options)}</Box>;
    }
};

const renderTitlesByKey = (plans, key: string) => {
    const planName = plans.planName ? plans.planName : 'Swipe to see other plans';
    return (
        <Grid display="flex" flexDirection="row" container>
            {key === 'planName' ? (
                <TopTile
                    xs={12}
                    item
                    sx={{
                        background: plans.primaryColor ? plans.primaryColor : 'white',
                        color: plans.primaryColor == 'white' ? '#000' : '#FFF',
                        padding: plans.primaryColor == 'white' ? '' : '21px 41px',
                        borderTopRightRadius: planName == 'Swipe to see other plans' ? '' : '8px',
                        borderTopLeftRadius: planName == 'Swipe to see other plans' ? '8px' : '',
                    }}
                >
                    <Typography fontSize="18px" textAlign="center" color="" fontWeight="bold">
                        {planName}
                    </Typography>
                </TopTile>
            ) : key === 'planDetailsText' || key === 'otherFeaturesText' ? (
                plans[key] && (
                    <PlanTile xs={12} sx={{ backgroundColor: '#F7F1FD', '& > p': { fontWeight: 'bold' } }}>
                        {displayText(plans[key])}
                    </PlanTile>
                )
            ) : key === 'checkPlanPriceText' ? (
                <BottomTile
                    xs={12}
                    item
                    sx={{
                        background: plans.primaryColor ? plans.primaryColor : 'white',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        component={Link}
                        href={plans['policyLink']}
                        fontSize=".75rem"
                        color="text.white"
                        sx={{ textDecorationColor: 'inherit', cursor: 'pointer' }}
                    >
                        {plans['readPolicyWordingText']}
                    </Typography>
                </BottomTile>
            ) : (
                <PlanTile xs={12} item sx={{ background: plans.secondaryColor ? plans.secondaryColor : 'white' }}>
                    {plans[key] ? <Box>{displayText(plans[key])}</Box> : <Box sx={{ textAlign: 'center' }}>-</Box>}
                </PlanTile>
            )}
        </Grid>
    );
};

function PlansPanelMobile(planInfo: PlanInfoProps) {
    for (let i = 0; i < planInfo.plans.length; i++) {
        for (var key in planInfo.plans[i]) {
            if (planInfo.plans[i].hasOwnProperty(key)) {
            }
        }
    }

    return (
        <TableContainer>
            <Grid container spacing={0}>
                <Grid item xs={6} sx={{ background: '#fff' }}>
                    <Box sx={{ minHeight: '750px' }}>
                        <Box>{renderTitlesByKey(planInfo.plans[0], 'planName')}</Box>
                        <Box>{renderTitlesByKey(planInfo.plans[0], 'planDetailsText')}</Box>
                        {/* <MediumBox sx={{ minHeight: '96px' }}>
                            {renderTitlesByKey(planInfo.plans[0], 'price')}
                        </MediumBox> */}
                        <MediumBox sx={{ minHeight: '96px' }}>
                            {renderTitlesByKey(planInfo.plans[0], 'screenRepairWithOemParts')}
                        </MediumBox>
                        <LargeBox>
                            {renderTitlesByKey(planInfo.plans[0], 'accidentalDamageLikeForLikeReplacement')}
                        </LargeBox>
                        <LargeBox>{renderTitlesByKey(planInfo.plans[0], 'lostStolenLikeForLikeReplacement')}</LargeBox>
                        <LargeBox minHeight={'200px'}>{renderTitlesByKey(planInfo.plans[0], 'techSupport')}</LargeBox>
                        <MediumBox minHeight={'120px'}>
                            {renderTitlesByKey(planInfo.plans[0], 'securityAdvisor')}
                        </MediumBox>
                    </Box>
                    <Box height={'72px'}>{renderTitlesByKey(planInfo.plans[0], 'otherFeaturesText')}</Box>
                    <Box>
                        <MediumBox>{renderTitlesByKey(planInfo.plans[0], 'nationwideDelivery')}</MediumBox>
                        <MediumBox>{renderTitlesByKey(planInfo.plans[0], 'nationwideRepairNetwork')}</MediumBox>
                        <MediumBox>{renderTitlesByKey(planInfo.plans[0], 'onlineClaims')}</MediumBox>
                        <MediumBox>{renderTitlesByKey(planInfo.plans[0], 'newusedPhoneCoverage')}</MediumBox>
                        <MediumBox>{renderTitlesByKey(planInfo.plans[0], 'cancelAnytime')}</MediumBox>
                        <MediumBox>{renderTitlesByKey(planInfo.plans[0], 'flexiblePaymentsMonthlyyearly')}</MediumBox>
                        <MediumBox>{renderTitlesByKey(planInfo.plans[0], 'checkPlanPriceText')}</MediumBox>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ minWidth: '150px', maxWidth: '200px' }}>
                        <SwiperPanel
                            spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            navigation={true}
                            modules={[Navigation]}
                            className="mySwiper"
                        >
                            {planInfo.plans.map((plan, index) => {
                                if (plan.planName != '') {
                                    return (
                                        <SwiperSlide>
                                            <Box
                                                sx={{
                                                    minHeight: '750px',
                                                    background: plan.secondaryColor ? plan.secondaryColor : 'white',
                                                }}
                                            >
                                                <Box sx={{ margin: '0 auto' }}>
                                                    {renderTitlesByKey(plan, 'planName')}
                                                </Box>
                                                <Box sx={{ background: '#F7F1FD', minHeight: '72px' }}>
                                                    {renderTitlesByKey(plan, 'planDetailsText')}{' '}
                                                </Box>
                                                {/* <MediumBox sx={{ height: '96px', background: '#fff' }}>
                                                    {renderTitlesByKey(plan, 'price')}
                                                </MediumBox> */}
                                                <MediumBox minHeight={'96px'}>
                                                    {renderTitlesByKey(plan, 'screenRepairWithOemParts')}
                                                </MediumBox>
                                                <LargeBox minHeight={'144px'}>
                                                    {renderTitlesByKey(plan, 'accidentalDamageLikeForLikeReplacement')}
                                                </LargeBox>
                                                <LargeBox minHeight={'120px'}>
                                                    {renderTitlesByKey(plan, 'lostStolenLikeForLikeReplacement')}
                                                </LargeBox>
                                                <LargeBox minHeight={'200px'}>
                                                    {renderTitlesByKey(plan, 'techSupport')}
                                                </LargeBox>
                                                <MediumBox minHeight={'120px'}>
                                                    {renderTitlesByKey(plan, 'securityAdvisor')}
                                                </MediumBox>
                                            </Box>
                                            <Box height={'72px'} sx={{ background: '#F7F1FD' }}>
                                                {renderTitlesByKey(plan, 'otherFeaturesText')}
                                            </Box>
                                            <Box
                                                sx={{ background: plan.secondaryColor ? plan.secondaryColor : 'white' }}
                                            >
                                                <MediumBox minHeight={'96px'}>
                                                    {renderTitlesByKey(plan, 'nationwideDelivery')}
                                                </MediumBox>
                                                <MediumBox minHeight={'96px'}>
                                                    {renderTitlesByKey(plan, 'nationwideRepairNetwork')}
                                                </MediumBox>
                                                <MediumBox minHeight={'96px'}>
                                                    {renderTitlesByKey(plan, 'onlineClaims')}
                                                </MediumBox>
                                                <MediumBox minHeight={'96px'}>
                                                    {renderTitlesByKey(plan, 'newusedPhoneCoverage')}
                                                </MediumBox>
                                                <MediumBox minHeight={'72px'}>
                                                    {renderTitlesByKey(plan, 'cancelAnytime')}
                                                </MediumBox>
                                                <MediumBox minHeight={'120px'}>
                                                    {renderTitlesByKey(plan, 'flexiblePaymentsMonthlyyearly')}
                                                </MediumBox>
                                                <Box minHeight={'120px'} display="flex">
                                                    {renderTitlesByKey(plan, 'checkPlanPriceText')}
                                                </Box>
                                            </Box>
                                        </SwiperSlide>
                                    );
                                }
                            })}
                        </SwiperPanel>
                    </Box>
                </Grid>
            </Grid>

            {/* <Box>{renderTilesByKey(planInfo.plans, 'planName')}</Box> */}
            {/* <Box>{renderTilesByKey(planInfo.plans, 'planDetailsText')}</Box>
        <Box>{renderTilesByKey(planInfo.plans, 'price')}</Box>
        <Box>{renderTilesByKey(planInfo.plans, 'screenRepairWithOemParts')}</Box>
        <Box>{renderTilesByKey(planInfo.plans, 'accidentalDamageLikeForLikeReplacement')}</Box>
        <Box>{renderTilesByKey(planInfo.plans, 'lostStolenLikeForLikeReplacement')}</Box>
        <Box>{renderTilesByKey(planInfo.plans, 'techSupport')}</Box>
        <Box>{renderTilesByKey(planInfo.plans, 'securityAdvisor')}</Box>
        <Box>{renderTilesByKey(planInfo.plans, 'otherFeaturesText')}</Box>
        <Box>{renderTilesByKey(planInfo.plans, 'nationwideDelivery')}</Box>
        <Box>{renderTilesByKey(planInfo.plans, 'nationwideRepairNetwork')}</Box>
        <Box>{renderTilesByKey(planInfo.plans, 'onlineClaims')}</Box>
        <Box>{renderTilesByKey(planInfo.plans, 'newusedPhoneCoverage')}</Box>
        <Box>{renderTilesByKey(planInfo.plans, 'cancelAnytime')}</Box>
        <Box>{renderTilesByKey(planInfo.plans, 'flexiblePaymentsMonthlyyearly')}</Box>
        <Box>{renderTilesByKey(planInfo.plans, 'checkPlanPriceText')}</Box>  */}
        </TableContainer>
    );
}

export default PlansPanelMobile;

import React, { useState, useRef } from 'react';
import { BannerProps } from './Hero';
import { Container, Box, Grid, Typography, Button, Link, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { getImage } from 'gatsby-plugin-image';
import { redirectWithParams } from '../../utils/Utils';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import parse from 'html-react-parser';

import ArrowLeft from '../../assets/arrow-left.svg';
import CurvedArrowWhite from '../../assets/hero-curved-arrow-white.svg';
import CurvedArrowBlack from '../../assets/hero-curved-arrow-black.svg';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

export interface SlideProps {
    isSlideBeginning: boolean;
    isSlideEnd: boolean;
    heroLength: number;
}

function BannerV3({ bannerInfo, slideInfo, partnerInfo }) {
    const banner: BannerProps = bannerInfo;

    const Bold = ({ children }) => (
        <Typography component="span" fontWeight="bold">
            {children}
        </Typography>
    );
    const Italic = ({ children }) => (
        <Typography component="span" fontStyle="italic" color="text.secondary">
            {children}
        </Typography>
    );
    const Text = ({ children }) => <Typography className="">{children}</Typography>;

    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
            [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
            [MARKS.CODE]: (text) => parse(`${text}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) =>
                node.content.some(
                    (childNode) =>
                        childNode.nodeType === `text` && childNode.marks.some((mark) => mark.type === MARKS.CODE)
                ) ? (
                    children
                ) : (
                    <Text>{children}</Text>
                ),
        },
    };

    return (
        <Box
            height="100%"
            sx={{
                background: `${banner.backgroundColorGradient}`,
            }}
        >
            {/* <BackgroundImage
                {...convertToBgImage(getImage(banner.heroImage))}
                preserveStackingContext
                style={{
                    height: '100%',
                    zIndex: 1,
                    backgroundSize: '55% 100%',
                    backgroundPosition: 'right bottom',
                    position: 'relative',
                }}
                loading="eager"
            > */}
            {partnerInfo && (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="end"
                    width="100%"
                    sx={{
                        position: 'absolute',
                        background: 'linear-gradient(180deg, rgba(52, 14, 83, 0.8) 0%, rgba(52, 14, 83, 0) 100%);',
                    }}
                >
                    <Box component="img" src={partnerInfo.partnerLogo.file.url} alt="partner-logo" pt={4} mr="100px" />
                </Box>
            )}
            <Container maxWidth="lg" sx={{ height: '100%', padding: { lg: '0 !important' } }}>
                <BackgroundImage
                    {...convertToBgImage(getImage(banner.heroImage))}
                    preserveStackingContext
                    style={{
                        height: '100%',
                        zIndex: 1,
                        backgroundSize: '55% 100%',
                        backgroundPosition: 'right bottom',
                        position: 'relative',
                    }}
                    loading="eager"
                >
                    <Grid height="100%" container>
                        <Grid
                            xs={6}
                            height="100%"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="left"
                            color={`${banner.baseTextColor} !important`}
                            item
                        >
                            {banner.line1 && (
                                <Typography
                                    sx={{
                                        '& > p': {
                                            fontSize: '3rem',
                                            fontWeight: 'bold',
                                            lineHeight: '57.6px',
                                            margin: 0,
                                        },
                                    }}
                                >
                                    {renderRichText(banner.line1, options)}
                                </Typography>
                            )}
                            {banner.line2 && (
                                <Box
                                    sx={{
                                        '& > p': {
                                            fontSize: '1.5rem',
                                            lineHeight: '29.76px',
                                            margin: 0,
                                        },
                                    }}
                                >
                                    {renderRichText(banner.line2, options)}
                                </Box>
                            )}
                            {banner.line3 && (
                                <Box
                                    sx={{
                                        '& > p': {
                                            fontSize: '1.5rem',
                                            lineHeight: '29.76px',
                                            margin: 0,
                                        },
                                    }}
                                >
                                    {renderRichText(banner.line3, options)}
                                </Box>
                            )}

                            <Box display="flex" flexDirection="row" pt={3}>
                                <Box maxWidth="270px">
                                    {banner.line4 && (
                                        <Box sx={{ '& >p': { fontSize: '0.875rem', fontWeight: '700', margin: 0 } }}>
                                            {renderRichText(banner.line4, options)}
                                        </Box>
                                    )}
                                    <Button
                                        component={Link}
                                        href={banner.buttonLink}
                                        variant="contained"
                                        color="white"
                                        size="large"
                                        sx={{
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                            color: 'text.primaryv4',
                                            textTransform: 'none',
                                            mt: 1,
                                        }}
                                        id="btn-home-phone-eligibility"
                                    >
                                        {banner.buttonText}
                                    </Button>
                                </Box>
                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                    {banner.baseTextColor === 'black' ? <CurvedArrowBlack /> : <CurvedArrowWhite />}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </BackgroundImage>
            </Container>
            {/* </BackgroundImage> */}
        </Box>
    );
}

export default BannerV3;

import React from 'react';
import { Container, Box, Grid, Link, Typography } from '@mui/material';
import { ClaimsProps } from './TabContent';
import ContentCardNoDescription from './ContentCardNoDescription';
import ContentCardNoDescriptionHorizontal from './ContentCardNoDescriptionHorizontal';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { options } from '../../utils/Utils';
import { TypeD2cGenericArticleCardFields } from '../../types/my-types';

function ClaimsPanelMobile({ claimsInfo, disclaimerText }) {
    return (
        <Box>
            <Grid display="flex" flexDirection="row" container mb={4}>
                <Box mb={2}>
                    <Typography fontSize="1.5rem" lineHeight="29.76px" color="text.primaryv4" fontWeight="bold" mb={1}>
                        {claimsInfo.makeAClaimTitle}
                    </Typography>

                    <Typography fontSize="1.125rem" color="text.primaryv4" pr={3}>
                        {renderRichText(claimsInfo.makeAClaimDescription, options)}
                    </Typography>
                </Box>
                <Grid spacing={2} container mb={4}>
                    {claimsInfo.claimSteps.map((step: TypeD2cGenericArticleCardFields) => (
                        <Grid key={step.id} xs={12} md={6} item>
                            <ContentCardNoDescriptionHorizontal {...step} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            {/* <Grid display="flex" flexDirection="row" container>
                <Box mb={2}>
                    <Typography fontSize="1.5rem" lineHeight="29.76px" color="text.primaryv4" fontWeight="bold">
                        {claimsInfo.applicationStepsTitle}
                    </Typography>

                    <Typography fontSize="1.125rem" color="text.primaryv4">
                        {claimsInfo.applicationStepsDescription}
                    </Typography>
                </Box>
                <Grid spacing={2} container>
                    {claimsInfo.applicationSteps.map((step: TypeD2cGenericArticleCardFields) => (
                        <Grid key={step.id} xs={12} md={6} item>
                            <ContentCardNoDescription {...step} />
                        </Grid>
                    ))}
                </Grid>
            </Grid> */}
            <Box mt={3} mb={2}>
                <Typography fontSize=".75rem" color="text.disabled" sx={{ '& > p > a': { color: 'text.disabled' } }}>
                    {renderRichText(disclaimerText, options)}
                </Typography>
            </Box>
        </Box>
    );
}

export default ClaimsPanelMobile;

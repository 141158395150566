import React from 'react';
import { Grid, Box, Typography, Link } from '@mui/material';
import { PlanInfoProps, PlanProps } from './TabContent';
import { styled } from '@mui/material/styles';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { options, redirectWithParams } from '../../utils/Utils';
import CheckIcon from '../../assets/checksvg.svg';
import CrossIcon from '../../assets/crosssvg.svg';

const TableContainer = styled(Box)({
    filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.12))',
});

const PlanTile = styled(Grid)({
    padding: '24px 16px',
    '& div > p': {
        fontSize: '1rem',
    },
    '& div > p > span': {
        fontSize: '1.5rem !important',
    },
});

const TopTile = styled(PlanTile)({
    textAlign: 'center',
    '&:first-child': {
        borderTopLeftRadius: '8px',
    },
    '&:last-child': {
        borderTopRightRadius: '8px',
    },
});

const BottomTile = styled(PlanTile)({
    textAlign: 'center',
    '&:first-child': {
        borderBottomLeftRadius: '8px',
    },
    '&:last-child': {
        borderBottomRightRadius: '8px',
    },
});

const displayText = (value: any) => {
    if (value === null) {
        return <Typography textAlign="center">-</Typography>;
    } else if (typeof value === 'string') {
        return <Typography>{value}</Typography>;
    } else if (typeof value === 'boolean') {
        if (value) {
            return (
                <Box textAlign="center">
                    <CheckIcon />
                </Box>
            );
        } else {
            return (
                <Box textAlign="center">
                    <CrossIcon />
                </Box>
            );
        }
    } else {
        return <Box mb={1}>{renderRichText(value, options)}</Box>;
    }
};

const renderTilesByKey = (plans: PlanProps[], key: string) => {
    return (
        <Grid display="flex" flexDirection="row" container>
            {plans.map((plan, index) => (
                <>
                    {console.log(plan)}

                    {key === 'planName' ? (
                        <TopTile
                            xs={3}
                            item
                            sx={{
                                background: plan.primaryColor ? plan.primaryColor : 'white',
                            }}
                        >
                            <Typography fontSize="1.5rem" textAlign="center" color="text.white" fontWeight="bold">
                                {plan[key]}
                            </Typography>
                        </TopTile>
                    ) : key === 'planDetailsText' || key === 'otherFeaturesText' ? (
                        plan[key] && (
                            <PlanTile xs={12} sx={{ backgroundColor: '#F7F1FD', '& > p': { fontWeight: 'bold' } }}>
                                {displayText(plan[key])}
                            </PlanTile>
                        )
                    ) : key === 'checkPlanPriceText' ? (
                        <BottomTile
                            xs={3}
                            item
                            sx={{
                                background: plan.primaryColor ? plan.primaryColor : 'white',
                                textAlign: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography
                                component={Link}
                                href={plan['policyLink']}
                                fontSize=".75rem"
                                color="text.white"
                                sx={{ textDecorationColor: 'inherit', cursor: 'pointer' }}
                            >
                                {plan['readPolicyWordingText']}
                            </Typography>
                        </BottomTile>
                    ) : (
                        <PlanTile xs={3} item sx={{ background: plan.secondaryColor ? plan.secondaryColor : 'white' }}>
                            {displayText(plan[key])}
                        </PlanTile>
                    )}
                </>
            ))}
        </Grid>
    );
};

function PlansPanel(planInfo: PlanInfoProps) {
    for (let i = 0; i < planInfo.plans.length; i++) {
        for (var key in planInfo.plans[i]) {
            if (planInfo.plans[i].hasOwnProperty(key)) {
            }
        }
    }
    return (
        <TableContainer>
            <Box>{renderTilesByKey(planInfo.plans, 'planName')}</Box>
            <Box>{renderTilesByKey(planInfo.plans, 'planDetailsText')}</Box>
            {/* <Box>{renderTilesByKey(planInfo.plans, 'price')}</Box> */}
            <Box>{renderTilesByKey(planInfo.plans, 'screenRepairWithOemParts')}</Box>
            <Box>{renderTilesByKey(planInfo.plans, 'accidentalDamageLikeForLikeReplacement')}</Box>
            <Box>{renderTilesByKey(planInfo.plans, 'lostStolenLikeForLikeReplacement')}</Box>
            <Box>{renderTilesByKey(planInfo.plans, 'techSupport')}</Box>
            <Box>{renderTilesByKey(planInfo.plans, 'securityAdvisor')}</Box>
            <Box>{renderTilesByKey(planInfo.plans, 'otherFeaturesText')}</Box>
            <Box>{renderTilesByKey(planInfo.plans, 'nationwideDelivery')}</Box>
            <Box>{renderTilesByKey(planInfo.plans, 'nationwideRepairNetwork')}</Box>
            <Box>{renderTilesByKey(planInfo.plans, 'onlineClaims')}</Box>
            <Box>{renderTilesByKey(planInfo.plans, 'newusedPhoneCoverage')}</Box>
            <Box>{renderTilesByKey(planInfo.plans, 'cancelAnytime')}</Box>
            <Box>{renderTilesByKey(planInfo.plans, 'flexiblePaymentsMonthlyyearly')}</Box>
            <Box>{renderTilesByKey(planInfo.plans, 'checkPlanPriceText')}</Box>
        </TableContainer>
    );
}

export default PlansPanel;

import React, { useState, useEffect, memo, useMemo } from 'react';
import { AudioCard, VideoCard } from 'material-ui-player';
import { getImage, ImageDataLike, GatsbyImage } from 'gatsby-plugin-image';
import {
    Box,
    Container,
    Grid,
    Typography,
    Button,
    Modal,
    Fade,
    Backdrop,
    Card,
    CardActionArea,
    CardMedia,
    useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';
import PlayButtonMobile from '../../assets/play-btn-mobile.svg';

export interface VideoProps {
    thumbnailImage: ImageDataLike;
    video: string;
    videoImage: ImageDataLike[];
}

const StyledModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});
const Overlay = styled(Box)({
    background: 'rgba(17, 17, 17, 0.32)',
    height: '100%',
});

function Video(videoInfo: VideoProps) {
    const [showModal, setShowModal] = useState<boolean>(false);
    const theme = useTheme();
    const isMobile: Boolean = useMediaQuery(theme.breakpoints.down('lg'));
    const isSmall: Boolean = useMediaQuery(theme.breakpoints.down('sm'));

    const handleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <Box sx={{ background: '#793CBE' }}>
            {isMobile ? (
                <Grid
                    display="flex"
                    direction="column"
                    justifyContent="space-evenly"
                    sx={{ height: '480px' }}
                    container
                    id="about-asurion"
                >
                    <BackgroundImage
                        {...convertToBgImage(getImage(videoInfo.videoImage[0]))}
                        preserveStackingContext
                        style={{
                            height: '100%',
                            zIndex: 1,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                        loading="eager"
                        onClick={() => handleModal()}
                        id="btn-home-asurion-story"
                    ></BackgroundImage>
                </Grid>
            ) : (
                <Container
                    maxWidth="xl"
                    sx={{
                        height: '100%',
                        background: '#793CBE',
                        borderBottom: '#793CBE 1px solid',
                    }}
                >
                    <Grid display="flex" sx={{ height: '100%' }} id="about-asurion" pt={2}>
                        <Grid
                            lg={12}
                            md={12}
                            sm={12}
                            height="100%"
                            flexDirection="column"
                            display="flex"
                            justifyContent="center"
                            item
                        >
                            <Box position="relative" width="100%">
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    sx={{
                                        '&:hover': {
                                            cursor: 'pointer',
                                        },
                                    }}
                                    onClick={() => handleModal()}
                                    id="btn-home-asurion-story"
                                >
                                    <GatsbyImage image={getImage(videoInfo.thumbnailImage)} height="408px" />
                                    {/* <PlayButton onClick={() => handleModal()} id="btn-home-asurion-story" /> */}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            )}
            <StyledModal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showModal}
                onClose={handleModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showModal}>
                    <div>
                        <Grid direction="row" container>
                            <Card raised={true}>
                                <CardActionArea>
                                    <CardMedia
                                        component="video"
                                        src={videoInfo.video}
                                        controls
                                        autoPlay
                                        height={isMobile ? '100%' : '360px'}
                                        width={isMobile ? '100%' : '640px'}
                                    />
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </div>
                </Fade>
            </StyledModal>
        </Box>
    );
}

export default Video;

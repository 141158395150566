import React from 'react';
import { Container, Box, Grid, Typography, Button, Link, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';
import { getImage, GatsbyImage, ImageDataLike } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { redirectWithParams } from '../../utils/Utils';
import CurvedArrowWhite from '../../assets/hero-curved-arrow-white.svg';
import CurvedArrowBlack from '../../assets/hero-curved-arrow-black.svg';
import { BannerProps } from './Hero';
import { useTheme } from '@mui/material/styles';
import ArrowLeft from '../../assets/arrow-left.svg';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import parse from 'html-react-parser';
import { isBoxedPrimitive } from 'util/types';

const Bold = ({ children }) => (
    <Typography component="span" fontWeight="bold">
        {children}
    </Typography>
);
const Italic = ({ children }) => (
    <Typography component="span" fontStyle="italic" color="text.secondary">
        {children}
    </Typography>
);
const Text = ({ children }) => <Typography className="">{children}</Typography>;

const options = {
    renderMark: {
        [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
        [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
        [MARKS.CODE]: (text) => parse(`${text}`),
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) =>
            node.content.some(
                (childNode) => childNode.nodeType === `text` && childNode.marks.some((mark) => mark.type === MARKS.CODE)
            ) ? (
                children
            ) : (
                <Text>{children}</Text>
            ),
    },
};
function BannerV4({ bannerInfo, slideInfo, partnerInfo }) {
    const StyledBox = styled(Box)({
        height: '100%',
        background: '#671CA6',
    });

    const banner: BannerProps = bannerInfo;

    return (
        <StyledBox>
            <BackgroundImage
                {...convertToBgImage(getImage(banner.bannerBackgroundImage))}
                preserveStackingContext
                style={{
                    height: '100%',
                    zIndex: 1,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
                loading="eager"
            >
                {/* <BackgroundImage
                    {...convertToBgImage(getImage(banner.heroImage))}
                    preserveStackingContext
                    style={{
                        height: '100%',
                        zIndex: 1,
                        backgroundSize: 'auto 90%',
                        backgroundPosition: 'right top',
                        position: 'relative',
                    }}
                    loading="eager"
                > */}
                {partnerInfo && (
                    <Grid
                        display="flex"
                        flexDirection="row"
                        justifyContent="end"
                        width="100%"
                        container
                        sx={{
                            position: 'absolute',
                            background: 'linear-gradient(180deg, rgba(52, 14, 83, 0.8) 0%, rgba(52, 14, 83, 0) 100%);',
                        }}
                    >
                        {/* <Box
                            component="img"
                            src={partnerInfo.partnerLogo.file.url}
                            alt="partner-logo"
                            pt={4}
                            mr="100px"
                        /> */}
                    </Grid>
                )}
                <Container maxWidth="lg" sx={{ height: '100%', padding: { lg: '0 !important' } }}>
                    <Grid height="100%" container>
                        <Grid
                            xs={6}
                            height="100%"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="left"
                            item
                            sx={{
                                color: `${banner.baseTextColor} !important`,
                            }}
                        >
                            {banner.line1 && (
                                <Typography
                                    sx={{
                                        '& > p': {
                                            fontSize: '3rem',
                                            fontWeight: 'bold',
                                            lineHeight: '57.6px',
                                            color: `${banner.baseTextColor} !important`,
                                        },
                                    }}
                                >
                                    {renderRichText(banner.line1, options)}
                                </Typography>
                            )}
                            {banner.line2 && (
                                <Box
                                    sx={{
                                        '& > p': {
                                            fontSize: '1.5rem',
                                            lineHeight: '29.76px',
                                            color: `${banner.baseTextColor} !important`,
                                        },
                                    }}
                                >
                                    {renderRichText(banner.line2, options)}
                                </Box>
                            )}
                            {banner.line3 && (
                                <Box
                                    sx={{
                                        '& > p': {
                                            fontSize: '1.5rem',
                                            lineHeight: '29.76px',
                                            color: `${banner.baseTextColor} !important`,
                                        },
                                    }}
                                >
                                    {renderRichText(banner.line3, options)}
                                </Box>
                            )}

                            <Grid display="flex" flexDirection="row" pt={3} container>
                                <Box maxWidth="270px">
                                    {banner.line4 && (
                                        <Box
                                            sx={{
                                                '& > p': {
                                                    fontSize: '0.875rem',
                                                    color: '#FBE83B',
                                                    fontWeight: 'bold',
                                                },
                                            }}
                                        >
                                            {renderRichText(banner.line4, options)}
                                        </Box>
                                    )}
                                    <Button
                                        component={Link}
                                        href={banner.buttonLink}
                                        variant="contained"
                                        color="white"
                                        size="large"
                                        sx={{
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                            color: 'text.primaryv4',
                                            textTransform: 'none',
                                            mt: 1,
                                        }}
                                        id="btn-home-phone-eligibility"
                                    >
                                        {banner.buttonText}
                                    </Button>
                                </Box>
                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                    {banner.baseTextColor === 'black' ? <CurvedArrowBlack /> : <CurvedArrowWhite />}
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid xs={6} item>
                            <Box height="100%">
                                {partnerInfo && (
                                    <Box display="flex" flexDirection="row" pt={3} pl={3}>
                                        <Box
                                            component="img"
                                            src={partnerInfo.partnerLogo.file.url}
                                            alt="partner-logo"
                                        />
                                    </Box>
                                )}

                                <BackgroundImage
                                    {...convertToBgImage(getImage(banner.heroImage))}
                                    preserveStackingContext
                                    style={{
                                        zIndex: 1,
                                        height: partnerInfo ? 'calc(100% - 55px)' : '100%',
                                        backgroundSize: 'contain',
                                        backgroundPosition: 'left bottom',
                                        position: 'relative',
                                    }}
                                    loading="eager"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                {/* </BackgroundImage> */}
            </BackgroundImage>
        </StyledBox>
    );
}

export default BannerV4;

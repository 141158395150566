import React, { useState } from 'react';
import { Container, Box, Typography, Tabs, Tab } from '@mui/material';
import { Inline, Block } from '@contentful/rich-text-types';
import {
    TypeD2cGenericArticleCardFields,
    TypeGenericArticlesWithAnchorTextsFields,
    TypeContentfulStandardImage,
} from '../../types/my-types';
import { styled } from '@mui/material/styles';
import { svgUrlToComponent } from '../../utils/Utils';
import FeaturesPanel from './FeaturesPanel';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { options } from '../../utils/Utils';
import ShieldActive from '../../assets/ShieldActive.svg';
import ShieldDefault from '../../assets/ShieldDefault.svg';
import HandshakeDefault from '../../assets/HandshakeDefault.svg';
import HandshakeActive from '../../assets/HandshakeActive.svg';
import ClipboardDefault from '../../assets/ClipboardDefault.svg';
import ClipboardActive from '../../assets/ClipboardActive.svg';
import CheckListDefault from '../../assets/CheckListDefault.svg';
import CheckListActive from '../../assets/CheckListActive.svg';
import PlanTile from './PlanTile';
import PlansPanel from './PlansPanel';
import ClaimsPanel from './ClaimsPanel';
import WhyPanel from './WhyPanel';

export interface TabContentProps {
    features: FeaturesProps;
    plan: PlanInfoProps;
    claims: ClaimsProps;
    whyAsurion: WhyProps;
    tabDisclaimerText: Block | Inline;
}

export interface FeaturesProps {
    featuresTabText: string;
    featuesTabHighlightTitle: string;
    featuresTabHighlightDescription: Block | Inline;
    featuresImage: TypeContentfulStandardImage;
    featuresCards: TypeD2cGenericArticleCardFields[];
    featureMoreReasonsTitle: string;
    featuresMoreReasonsDescription: string;
    featuresMoreReasons: TypeD2cGenericArticleCardFields[];
}

export interface PlanInfoProps {
    planTabText: string;
    plans: PlanProps[];
}
export interface PlanProps {
    primaryColor: string;
    secondaryColor: string;
    planName: string;
    whoIsItFor: string;
    bestFor: string;
    price: Block | Inline | string;
    screenRepairWithOemParts: string;
    accidentalDamageLikeForLikeReplacement: string;
    lostStolenLikeForLikeReplacement: string;
    techSupport: Block | Inline | string;
    securityAdvisor: boolean | string;
    nationwideDelivery: boolean | string;
    nationwideRepairNetwork: boolean | string;
    onlineClaims: boolean | string;
    newusedPhoneCoverage: boolean | string;
    cancelAnytime: boolean | string;
    flexiblePaymentsMonthlyyearly: boolean | string;
    checkPlanPriceText: string;
    readPolicyWordingText: string;
    policyLink: string;
    planDetailsText: string;
    otherFeaturesText: string;
}
export interface ClaimsProps {
    claimsTabText: string;
    makeAClaimTitle: string;
    makeAClaimDescription: Block | Inline;
    claimSteps: TypeD2cGenericArticleCardFields[];
    applicationStepsTitle: string;
    applicationStepsDescription: string;
    applicationSteps: TypeD2cGenericArticleCardFields[];
}
export interface WhyProps {
    whyAsurionTabText: string;
    whyAsurionHighlightTitle: string;
    whyAsurionHighlightDescription: Inline;
    whyAsurionHighlightImage: TypeContentfulStandardImage;
    whyAsurionContent: TypeGenericArticlesWithAnchorTextsFields[];
}

const StyledTabs = styled(Tabs)({
    height: '100%',
    backgroundColor: '#793CBE',

    '& .MuiTabs-flexContainer': {
        height: '100%',
    },
    '& .Mui-selected': {
        backgroundColor: 'white',
        color: '#671CA6',
        fontWeight: 'bold',
        borderBottom: 'none',
    },
});
const StyledTab = styled(Tab)({
    border: ' 1px solid #4C147A',
    borderRadius: ' 8px 8px 0px 0px',
    color: 'white',
    height: '100%',
    justifyContent: 'start',
    fontWeight: 'bold',
    background: '#671CA6',
    textTransform: 'none',
    fontSize: '1rem',
});

const PanelBox = styled(Box)({
    padding: '48px 80px',
    background: '#FFFFFF',
    borderRadius: '0px 0px 8px 8px',
});

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <PanelBox>{children}</PanelBox>}
        </div>
    );
}

function TabContent(tabContentInfo: TabContentProps) {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <Box mb={10}>
            <Box height="72px" sx={{ background: '#793CBE' }}>
                <Container maxWidth="xl" sx={{ height: '100%', maxWidth: '1400px !important' }}>
                    <StyledTabs
                        value={selectedTab}
                        onChange={handleChange}
                        variant="fullWidth"
                        sx={{ height: '100%' }}
                        TabIndicatorProps={{
                            style: {
                                display: 'none',
                            },
                        }}
                    >
                        <StyledTab
                            icon={selectedTab === 0 ? <ShieldActive /> : <ShieldDefault />}
                            iconPosition="start"
                            label={tabContentInfo.features.featuresTabText}
                            color="text.white"
                        />
                        <StyledTab
                            icon={selectedTab === 1 ? <CheckListActive /> : <CheckListDefault />}
                            iconPosition="start"
                            label={tabContentInfo.plan.planTabText}
                            color="text.white"
                        />
                        <StyledTab
                            icon={selectedTab === 2 ? <ClipboardActive /> : <ClipboardDefault />}
                            iconPosition="start"
                            label={tabContentInfo.claims.claimsTabText}
                            color="text.white"
                        />
                        {/* <StyledTab
                            icon={selectedTab === 3 ? <HandshakeActive /> : <HandshakeDefault />}
                            iconPosition="start"
                            label={tabContentInfo.whyAsurion.whyAsurionTabText}
                            color="text.white"
                        /> */}
                    </StyledTabs>
                </Container>
            </Box>
            <Box>
                <Container maxWidth="xl" sx={{ maxWidth: '1400px !important' }}>
                    <TabPanel value={selectedTab} index={0}>
                        <FeaturesPanel {...tabContentInfo.features} />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <PlansPanel {...tabContentInfo.plan} />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                        <ClaimsPanel {...tabContentInfo.claims} />
                    </TabPanel>
                    {/* <TabPanel value={selectedTab} index={3}>
                        <WhyPanel {...tabContentInfo.whyAsurion} />
                    </TabPanel> */}
                    <PanelBox sx={{ pb: 3, pt: 0 }}>
                        <Typography
                            color="text.disabled"
                            px={3}
                            sx={{
                                '& > p': {
                                    fontSize: '0.75rem',
                                },
                                '& > p > a': {
                                    color: 'inherit',
                                    textDecorationColor: 'inherit',
                                },
                            }}
                        >
                            {renderRichText(tabContentInfo.tabDisclaimerText, options)}
                        </Typography>
                    </PanelBox>
                </Container>
            </Box>
        </Box>
    );
}

export default TabContent;

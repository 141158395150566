import React, { memo, useState, useEffect, useRef } from 'react';
import {
    Container,
    Box,
    Card,
    Grid,
    Typography,
    Rating,
    useMediaQuery,
    CardHeader,
    CardContent,
    Avatar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Autoplay, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { styled } from '@mui/material/styles';
import ArrowLeft from '../assets/arrow-left.svg';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import parse from 'html-react-parser';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

//old
// export interface ReviewsInfo {
//     title: string;
//     ratings: Review[];
//     ratingsIcons: string[];
//     description: string;
//     subtitle: string;
//     testimonials: Testimonial[];
// }

export interface ReviewsInfo {
    title: string;
    ratings: Review[];
    ratingsIcons: string[];
    description: string;
    testimonials: Testimonial[];
}

interface Review {
    name: string;
    rating: number;
}
interface Testimonial {
    userName: string;
    userRating: number;
    userImage: unknown;
    userLocation: string;
    userReview: ContentfulDocument;
}

const StyledRating = styled(Rating)({
    '&': {
        marginBottom: 2,
    },
});

const Bold = ({ children }) => (
    <Typography component="span" fontWeight="bold">
        {children}
    </Typography>
);
const Italic = ({ children }) => (
    <Typography component="span" fontStyle="italic" color="text.secondary">
        {children}
    </Typography>
);
const Text = ({ children }) => <Typography className="">{children}</Typography>;

const options = {
    renderMark: {
        [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
        [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
        [MARKS.CODE]: (text: string) => parse(`${text}`),
    },
    renderNode: {
        // [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        [BLOCKS.PARAGRAPH]: (node, children) =>
            node.content.some(
                (childNode) => childNode.nodeType === `text` && childNode.marks.some((mark) => mark.type === MARKS.CODE)
            ) ? (
                children
            ) : (
                <Text>{children}</Text>
            ),
    },
};

function Reviews(reviewsInfo: ReviewsInfo) {
    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.down('xl'));
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isTiny = useMediaQuery(theme.breakpoints.down('tiny'));
    const [isSlideBeginning, setIsSlideBeginning] = useState<boolean>(true);
    const [isSlideEnd, setIsSlideEnd] = useState<boolean>(false);
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    return (
        <Box sx={{ backgroundColor: isSmall ? '#FFFFFF' : '#F0F0F5' }}>
            {isMobile ? (
                <Grid
                    display="flex"
                    flexDirection="column"
                    alignItems="left"
                    justifyContent="left"
                    py={5}
                    sx={{ backgroundColor: '#671CA6' }}
                    container
                    id="reviews"
                >
                    <Typography color="text.white" fontSize="24px" textAlign="left"  pl={3} pr={3}>
                        {reviewsInfo.title}
                    </Typography>
                    <Typography color="text.white" fontSize="1.125rem" textAlign="left"  pl={3} pr={3}>
                        {reviewsInfo.description}
                    </Typography>
                    <Grid display="flex" flexDirection="row" justifyContent="left" mb={2} pt={2} container>
                        {isSmall ? (
                            <Swiper modules={[Autoplay]} spaceBetween={1} slidesPerView={1} grabCursor={true} autoplay>
                                <Box px={2}>
                                    {reviewsInfo.ratings.map((rating, index) => (
                                        <SwiperSlide>
                                            <Grid
                                                display="flex"
                                                flexDirection="row"
                                                justifyContent="center"
                                                alignItems="start"
                                                height="100%"
                                                sx={{ mt: 1 }}
                                                key={`rate-${index}`}
                                                item
                                            >
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    justifyContent="center"
                                                    sx={{ mr: 1 }}
                                                >
                                                    <img
                                                        src={reviewsInfo.ratingsIcons[index].file.url}
                                                        alt={`platform-logo-${index}`}
                                                        height="30px"
                                                    />
                                                </Box>
                                                <Box display="flex" flexDirection="column" justifyContent="center">
                                                    <Rating
                                                        name="read-only"
                                                        value={rating.rating}
                                                        precision={0.1}
                                                        readOnly
                                                    />
                                                </Box>
                                            </Grid>
                                        </SwiperSlide>
                                    ))}
                                </Box>
                            </Swiper>
                        ) : (
                            reviewsInfo.ratings.map((rating, index) => (
                                <Grid
                                    md={4}
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="center"
                                    key={`rate-${index}`}
                                    item
                                >
                                    <Box display="flex" flexDirection="column" justifyContent="center" sx={{ mr: 1 }}>
                                        <img
                                            src={reviewsInfo.ratingsIcons[index].file.url}
                                            alt={`platform-logo-${index}`}
                                            height="30px"
                                        />
                                    </Box>
                                    <Box display="flex" flexDirection="column" justifyContent="center">
                                        <Rating name="read-only" value={rating.rating} precision={0.1} readOnly />
                                    </Box>
                                </Grid>
                            ))
                        )}
                    </Grid>
                    <Grid xs={12} flexDirection="row" item>
                        <Swiper
                            onInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                            }}
                            onActiveIndexChange={({ realIndex, isBeginning, isEnd }) => {
                                setIsSlideBeginning(isBeginning);
                                setIsSlideEnd(isEnd);
                            }}
                            navigation={{
                                nextEl: '.swiper-next',
                                prevEl: '.swiper-prev',
                            }}
                            pagination={{
                                el: '.my-custom-pagination-div',
                                clickable: true,
                                bulletClass: 'pagination-bullet-unique',
                                bulletActiveClass: 'pagination-bullet-unique-active',
                            }}
                            modules={[Autoplay, Pagination, Navigation]}
                            spaceBetween={1}
                            slidesPerView={isMobile ? (isSmall ? 1.4 : 2.8) : 2}
                            grabCursor={true}
                        >
                            <Box px={2}>
                                {reviewsInfo.testimonials.map((testimonial, index) => (
                                    <SwiperSlide key={`testimonial-${index}`}>
                                        <Grid
                                            // xs={12}
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="center"
                                            alignItems="start"
                                            height="100%"
                                            sx={{ mt: 1 }}
                                            item
                                        >
                                            <Card
                                                sx={{
                                                    height: '312px',
                                                    width: '252px',
                                                    display: 'flex',
                                                    borderRadius: '16px',
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-between',
                                                    m: 2,
                                                }}
                                            >
                                                <CardContent sx={{ px: 2, pt: 2, pb: 1 }}>
                                                    <StyledRating
                                                        name="read-only"
                                                        value={testimonial.userRating}
                                                        max={5}
                                                        readOnly
                                                    />

                                                    {renderRichText(testimonial.userReview, options)}
                                                </CardContent>
                                                <CardHeader
                                                    sx={{ pt: 0, pb: 2, fontSize: '12px' }}
                                                    avatar={
                                                        <Avatar sx={{ bgcolor: 'primary.main' }}>{`${
                                                            testimonial.userName.split(' ')[0][0]
                                                        }${testimonial.userName.split(' ')[1][0]}`}</Avatar>
                                                    }
                                                    title={testimonial.userName}
                                                    subheader={testimonial.userLocation}
                                                    subheaderTypographyProps={{ color: 'black', fontSize: '12px' }}
                                                ></CardHeader>
                                            </Card>
                                        </Grid>
                                    </SwiperSlide>
                                ))}
                            </Box>
                        </Swiper>
                    </Grid>
                    <Grid display="flex" flexDirection="row" justifyContent="space-between" container>
                        <Grid
                            xs={6}
                            display="flex"
                            flexDirection="row"
                            justifyContent="start"
                            sx={{
                                '& > .swiper-pagination-bullets': {
                                    width: 'initial',
                                },
                            }}
                            item
                        >
                            <Box
                                className="my-custom-pagination-div"
                                display="flex"
                                justifyContent="start"
                                alignItems="center"
                                px={3}
                                sx={{
                                    '& > span': {
                                        mx: 1,
                                    },
                                }}
                            ></Box>
                        </Grid>
                        <Grid xs={6} display="flex" flexDirection="row" justifyContent="end" p={3} item>
                            <Box
                                className="swiper-prev"
                                sx={{
                                    '& > svg > circle': {
                                        fill: !isSlideBeginning && '#8223D2',
                                    },
                                }}
                            >
                                <ArrowLeft />
                            </Box>
                            <Box
                                className="swiper-next"
                                ml={2}
                                sx={{
                                    '& > svg': {
                                        transform: 'rotate(180deg)',
                                        '& > circle': { fill: !isSlideEnd && '#8223D2' },
                                    },
                                }}
                            >
                                <ArrowLeft />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Grid display="flex" flexDirection="row" container height="576px" id="reviews">
                    <Grid
                        lg={6}
                        display="flex"
                        flexDirection="column"
                        alignItems={isLarge ? 'center' : 'start'}
                        justifyContent="center"
                        sx={{ backgroundColor: '#671CA6' }}
                        pl={`${isLarge ? '0' : '15%'}`}
                        item
                    >
                        <Typography color="text.white" fontSize="2rem" mb={3}>
                            {reviewsInfo.title}
                        </Typography>
                        <Typography color="text.white" fontSize="1.125rem" mb={3}>
                            {reviewsInfo.description}
                        </Typography>
                        <Grid
                            display="flex"
                            flexDirection="column"
                            alignItems={isLarge && 'center'}
                            justifyContent="space-evenly"
                            container
                        >
                            {reviewsInfo.ratings.map((rating, index) => (
                                <Grid display="flex" flexDirection="row" key={`rate-${index}`} mb={5} item>
                                    <Box display="flex" flexDirection="column" justifyContent="center" sx={{ mr: 1 }}>
                                        <img
                                            src={reviewsInfo.ratingsIcons[index].file.url}
                                            alt={`platform-logo-${index}`}
                                            height="50px"
                                            width={`${reviewsInfo.ratingsIcons[index].width}px`}
                                        />
                                    </Box>
                                    <Box display="flex" flexDirection="column" justifyContent="center">
                                        <Rating name="read-only" value={rating.rating} precision={0.1} readOnly />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid
                        lg={6}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        pr={`${isLarge ? '0' : '15%'}`}
                        item
                    >
                        <Box>
                            <Swiper
                                onInit={(swiper) => {
                                    swiper.params.navigation.prevEl = prevRef.current;
                                    swiper.params.navigation.nextEl = nextRef.current;
                                    swiper.navigation.init();
                                    swiper.navigation.update();
                                }}
                                onActiveIndexChange={({ isBeginning, isEnd }) => {
                                    setIsSlideBeginning(isBeginning);
                                    setIsSlideEnd(isEnd);
                                }}
                                navigation={{
                                    nextEl: '.swiper-next',
                                    prevEl: '.swiper-prev',
                                }}
                                pagination={{
                                    el: '.my-custom-pagination-div',
                                    clickable: true,
                                    bulletClass: 'pagination-bullet-unique',
                                    bulletActiveClass: 'pagination-bullet-unique-active',
                                }}
                                modules={[Autoplay, Pagination, Navigation]}
                                spaceBetween={30}
                                slidesPerView={2}
                                grabCursor={true}
                            >
                                <Box p={2}>
                                    {reviewsInfo.testimonials.map((testimonial, index) => (
                                        <SwiperSlide key={`testimonial-${index}`}>
                                            <Card
                                                sx={{
                                                    height: '342px',
                                                    width: '284px',
                                                    display: 'flex',
                                                    borderRadius: '16px',
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-between',
                                                    m: 2,
                                                }}
                                            >
                                                <CardContent sx={{ px: 2, pt: 2, pb: 1 }}>
                                                    <StyledRating
                                                        name="read-only"
                                                        value={testimonial.userRating}
                                                        max={5}
                                                        readOnly
                                                    />

                                                    {renderRichText(testimonial.userReview, options)}
                                                </CardContent>
                                                <CardHeader
                                                    avatar={
                                                        <Avatar sx={{ bgcolor: 'primary.main' }}>{`${
                                                            testimonial.userName.split(' ')[0][0]
                                                        }${testimonial.userName.split(' ')[1][0]}`}</Avatar>
                                                    }
                                                    title={testimonial.userName}
                                                    subheader={testimonial.userLocation}
                                                    subheaderTypographyProps={{ color: 'black' }}
                                                ></CardHeader>
                                            </Card>
                                        </SwiperSlide>
                                    ))}
                                </Box>
                            </Swiper>
                        </Box>
                        <Grid display="flex" flexDirection="row" justifyContent="space-between" container>
                            <Grid
                                xs={6}
                                display="flex"
                                flexDirection="row"
                                justifyContent="start"
                                sx={{
                                    '& > .swiper-pagination-bullets': {
                                        width: 'initial',
                                    },
                                }}
                                item
                            >
                                <Box
                                    className="my-custom-pagination-div"
                                    display="flex"
                                    justifyContent="start"
                                    alignItems="center"
                                    px={3}
                                    sx={{
                                        '& > span': {
                                            mx: 1,
                                        },
                                    }}
                                ></Box>
                            </Grid>
                            <Grid xs={6} display="flex" flexDirection="row" justifyContent="end" p={3} item>
                                <Box
                                    className="swiper-prev"
                                    sx={{
                                        '& > svg > circle': {
                                            fill: !isSlideBeginning && '#8223D2',
                                        },
                                    }}
                                >
                                    <ArrowLeft />
                                </Box>
                                <Box
                                    className="swiper-next"
                                    ml={2}
                                    sx={{
                                        '& > svg': {
                                            transform: 'rotate(180deg)',
                                            '& > circle': { fill: !isSlideEnd && '#8223D2' },
                                        },
                                    }}
                                >
                                    <ArrowLeft />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}

export default memo(Reviews);

import React, { useState } from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import tipClose from '../../images/tip-close.png';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p className="align-center">{children}</p>;

// unused options
const options = {
    renderMark: {
        [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            return (
                <>
                    <h2>Embedded Asset</h2>
                    <pre>
                        <code>{JSON.stringify(node, null, 2)}</code>
                    </pre>
                </>
            );
        },
    },
};


const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#F0F3FF',
        color: '#2743CC',
        maxWidth: '300px',
        height: 'auto',
        fontSize: '14px',
        border: '1px solid #2743CC',
    },
}));

const MobileTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#F0F3FF',
        color: '#2743CC',
        marginLeft: '42px',
        marginRight: '8px',
        maxWidth: '100%',
        height: 'auto',
        fontSize: '14px',
        border: '1px solid #2743CC',


    },
}));

export default function FaqTooltip({ isMobile = false }) {
    const [isOpen, setOpen] = useState(false);

    const toggleTooltip = () => {
        setOpen(isOpen ? false : true);
    };


    const content = (
        <div>
            <div style={{ width: '100%', float: 'left' }}>
                <img
                    src={tipClose}
                    style={{ width: '24px', height: '24px', float: 'right', cursor: 'pointer' }}
                    onClick={toggleTooltip}
                />
            </div>
            <div>
                <p>
                    Yes, if you change your mind within the first 14 days and have not made a claim, we'll provide a full refund.
                </p>
                <p>You can cancel your Asurion Mobile+ Insurance policy within the first 14 days from when the policy starts (this is the Cooling-Off Period). We will provide you with a full refund of any premium and charges that you have paid, as long as you have not already made a claim.
                </p>
                <p>If you have selected the Prestige cover and have accessed our Expert Support services during the Cooling-Off Period, you will still be entitled to a full refund.</p>
            </div>
        </div>
    );

    const tipStyle = {
        width: isMobile ? '12px' : '16px',
        height: isMobile ? '12px' : '16px',
        cursor: 'pointer',
        color: '#0000EE',
        textDecoration: 'underline'
    };

    // const tip = <img src={tipIcon} style={tipStyle} onClick={toggleTooltip} alt="tipicon" />;
    const tip = <span onClick={toggleTooltip} style={tipStyle} >Read more</span>

    return isMobile ? (
        <MobileTooltip arrow={false} open={isOpen} placement={'bottom-end'} title={content}>
            {tip}
        </MobileTooltip>
    ) : (
        <HtmlTooltip arrow={false} open={isOpen} placement={'bottom'} title={content}>
            {tip}
        </HtmlTooltip>
    );
}


export const pageQuery = graphql`
    query FaqQuery {
        allposts: allContentfulFaQsHomePage(
            filter: { node_locale: { eq: "en-US" }, questionText: { eq: "Is there a Cooling-Off Period where I can get a full refund?" } }
        ) {
            nodes {
                answerText {
                    raw
                  }
            }
        }
        
    }
`;

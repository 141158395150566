import React from 'react';
import { Container, Box, Grid, Typography } from '@mui/material';
import { FeaturesProps } from './TabContent';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { options } from '../../utils/Utils';
import ContentCard from './ContentCard';

function FeaturesPanel(featuresInfo: FeaturesProps) {
    return (
        <Box>
            <Container maxWidth="lg" sx={{ height: '100%' }}>
                <Grid display="flex" flexDirection="row" container mb={4}>
                    <Grid xs={12} md={6} display="flex" flexDirection="column" justifyContent="center" item>
                        <Typography
                            fontSize="2rem"
                            lineHeight="39.68px"
                            color="text.primaryv4"
                            fontWeight="bold"
                            pr={20}
                            mb={1}
                        >
                            {featuresInfo.featuesTabHighlightTitle}
                        </Typography>
                        <Typography fontSize="1.125rem" pr={3}>
                            {renderRichText(featuresInfo.featuresTabHighlightDescription, options)}
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={6} item pr={2} minHeight="324px">
                        <img src={featuresInfo.featuresImage.file.url} alt={featuresInfo.featuresImage.file.fileName} />
                    </Grid>
                </Grid>
                <Grid spacing={2} container mb={4}>
                    {featuresInfo.featuresCards.map((feature) => (
                        <Grid xs={12} md={4} item>
                            <ContentCard {...feature} />
                        </Grid>
                    ))}
                </Grid>

                <Typography fontSize="1.5rem" fontWeight="bold" color="text.primaryv4" mb={1}>
                    {featuresInfo.featureMoreReasonsTitle}
                </Typography>
                <Grid spacing={2} container>
                    {featuresInfo.featuresMoreReasons.map((reasons) => (
                        <Grid xs={12} md={3} position="relative" item>
                            <ContentCard {...reasons} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}

export default FeaturesPanel;

import React, { useEffect, useState, useMemo, useRef } from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/SeoComponent';
import OgImage from '../images/OGImage.png';
import { sortBy, orderBy, get } from 'lodash';
import SpinnerOverlay from '../components/common/SpinnerOverlay';
import Layout from '../components/LayoutComponent';
import FAQ, { FAQInfo } from '../components/FAQNew';
import Reviews, { ReviewsInfo } from '../components/ReviewsNew';
import Hero, { HeroProps, BannerProps } from '../components/v4components/Hero';
import { CheckPricesProps, Device } from '../components/v4components/CheckPrices';
import TabContent, {
    TabContentProps,
    FeaturesProps,
    PlanInfoProps,
    ClaimsProps,
    WhyProps,
    PlanProps,
} from '../components/v4components/TabContent';
import TabContentMobile from '../components/v4components/TabContentMobile';

import Video, { VideoProps } from '../components/v4components/Video';
import Facts, { FactsProps } from '../components/v4components/Facts';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import HeroMobile from '../components/v4components/HeroMobile';

export default function index({ data, location }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [pageLoading, setPageLoading] = useState(true);
    const brandOrder = { Apple: 1, Samsung: 2, Oppo: 3 };
    const [scrollSection, setScrollSection] = useState<string>(null);
    const isBrowser = typeof window !== 'undefined';
    const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
    var aboutRef = useRef(null);

    useEffect(() => {
        planInfo.plans.unshift(planLabelColumn);
        if (data) {
            setTimeout(() => {
                setPageLoading(false);
            }, 1000);
        }
    }, [data]);

    useEffect(() => {
        if (isBrowser) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const goToSection = urlParams.get('view');
            setTimeout(() => {
                scrollToSection(goToSection);
            }, 1000);
        }
    }, []);

    const scrollToSection = (sectionId: string) => {
        var section = document.querySelector(`#${sectionId}`);
        if (section !== null) {
            setScrollSection(sectionId);
            if (sectionId === 'about-asurion') {
                scrollToRef(aboutRef);
            }
            setScrollSection(null);
        }
    };
    const announcementMessage: string = get(data, 'contentfulD2CHomepage.announcementMessage');
    console.log(announcementMessage);

    const bannerInfo: BannerProps[] = useMemo(() => get(data, 'contentfulD2CHomepage.banners'), data);
    const allDevices: Device[] = get(data, 'allContentfulDeviceDetailPage.nodes');
    const checkPricesInfo: CheckPricesProps = useMemo(
        () => ({
            ctaImage: get(data, 'contentfulD2CHomepage.ctaImage'),
            ctaTitle: get(data, 'contentfulD2CHomepage.ctaTitle'),
            ctaButtonText: get(data, 'contentfulD2CHomepage.ctaButtonText'),
            devices: orderBy(allDevices, (o) => [brandOrder[o.brandName]], ['asc']),
        }),
        data
    );

    const heroInfo: HeroProps = useMemo(
        () => ({
            banners: bannerInfo,
            checkPrices: checkPricesInfo,
            partner: null,
        }),
        data
    );

    const featuresInfo: FeaturesProps = useMemo(
        () => ({
            featuresTabText: get(data, 'contentfulD2CHomepage.featuresTabText'),
            featuesTabHighlightTitle: get(data, 'contentfulD2CHomepage.featuesTabHighlightTitle'),
            featuresTabHighlightDescription: get(data, 'contentfulD2CHomepage.featuresTabHighlightDescription'),
            featuresImage: get(data, 'contentfulD2CHomepage.featuresImage'),
            featuresCards: get(data, 'contentfulD2CHomepage.featuresCards'),
            featureMoreReasonsTitle: get(data, 'contentfulD2CHomepage.featureMoreReasonsTitle'),
            featuresMoreReasonsDescription: get(data, 'contentfulD2CHomepage.featuresMoreReasonsDescription'),
            featuresMoreReasons: get(data, 'contentfulD2CHomepage.featuresMoreReasons'),
        }),
        data
    );

    const planInfo: PlanInfoProps = useMemo(
        () => ({
            planTabText: get(data, 'contentfulD2CHomepage.planTabText'),
            plans: get(data, 'contentfulD2CHomepage.plans'),
        }),
        data
    );

    const claimsInfo: ClaimsProps = useMemo(
        () => ({
            claimsTabText: get(data, 'contentfulD2CHomepage.claimsTabText'),
            makeAClaimTitle: get(data, 'contentfulD2CHomepage.makeAClaimTitle'),
            makeAClaimDescription: get(data, 'contentfulD2CHomepage.makeAClaimDescription'),
            claimSteps: get(data, 'contentfulD2CHomepage.claimSteps'),
            applicationStepsTitle: get(data, 'contentfulD2CHomepage.applicationStepsTitle'),
            applicationStepsDescription: get(data, 'contentfulD2CHomepage.applicationStepsDescription'),
            applicationSteps: get(data, 'contentfulD2CHomepage.applicationSteps'),
        }),
        data
    );

    const whyInfo: WhyProps = useMemo(
        () => ({
            whyAsurionTabText: get(data, 'contentfulD2CHomepage.whyAsurionTabText'),
            whyAsurionHighlightTitle: get(data, 'contentfulD2CHomepage.whyAsurionHighlightTitle'),
            whyAsurionHighlightDescription: get(data, 'contentfulD2CHomepage.whyAsurionHighlightDescription'),
            whyAsurionHighlightImage: get(data, 'contentfulD2CHomepage.whyAsurionHighlightImage'),
            whyAsurionContent: get(data, 'contentfulD2CHomepage.whyAsurionContent'),
        }),
        data
    );

    const tabContentInfo: TabContentProps = useMemo(
        () => ({
            features: featuresInfo,
            plan: planInfo,
            claims: claimsInfo,
            whyAsurion: whyInfo,
            tabDisclaimerText: get(data, 'contentfulD2CHomepage.tabsDisclaimerText'),
        }),
        data
    );

    const faqInfo: FAQInfo = useMemo(
        () => ({
            title: get(data, 'contentfulD2CHomepage.faqTitle'),
            description: get(data, 'contentfulD2CHomepage.faqDescription'),
            queries: get(data, 'contentfulD2CHomepage.faqs'),
        }),
        data
    );

    const reviewsInfo: ReviewsInfo = useMemo(
        () => ({
            title: get(data, 'contentfulD2CHomepage.reviewTitle'),
            description: get(data, 'contentfulD2CHomepage.reviewDescription'),
            ratings: get(data, 'contentfulD2CHomepage.reviewPlatformRatings'),
            ratingsIcons: get(data, 'contentfulD2CHomepage.reviewPlatformLogos'),
            testimonials: get(data, 'contentfulD2CHomepage.reviews'),
        }),
        data
    );

    const planLabelColumn: PlanProps = {
        primaryColor: 'white',
        secondaryColor: 'white',
        planName: '',
        whoIsItFor: 'Who is it for?',
        bestFor: '',
        price: 'Price',
        screenRepairWithOemParts: 'Screen Repair with OEM parts',
        accidentalDamageLikeForLikeReplacement: 'Accidental Damage Like-for-Like Replacement',
        lostStolenLikeForLikeReplacement: 'Lost & Stolen Like-for-Like Replacement',
        techSupport: 'Tech Support',
        securityAdvisor: 'Security Advisor',
        nationwideDelivery: 'Nationwide delivery',
        nationwideRepairNetwork: 'Nationwide repair network',
        onlineClaims: '24/7 online claims',
        newusedPhoneCoverage: 'New/used phone coverage',
        cancelAnytime: 'Cancel anytime',
        flexiblePaymentsMonthlyyearly: 'Flexible payments (monthly/yearly)',
        checkPlanPriceText: '',
        readPolicyWordingText: '',
        policyLink: '',
        planDetailsText: 'Plan details',
        otherFeaturesText: 'Other features',
    };
    const videoInfo: VideoProps = useMemo(
        () => ({
            thumbnailImage: get(data, 'contentfulD2CHomepage.videoThumbnailImage'),
            video: get(data, 'contentfulD2CHomepage.video.file.url'),
            videoImage: get(data, 'contentfulD2CHomepage.videoImage'),
        }),
        data
    );

    const factsInfo: FactsProps = useMemo(
        () => ({
            line1: get(data, 'contentfulD2CHomepage.facts.line1'),
            line2: get(data, 'contentfulD2CHomepage.facts.line2'),
            line3: get(data, 'contentfulD2CHomepage.facts.line3'),
            line4: get(data, 'contentfulD2CHomepage.facts.line4'),
            linkUrl: get(data, 'contentfulD2CHomepage.facts.linkUrl'),
            linkText: get(data, 'contentfulD2CHomepage.facts.linkText'),
            supportedPhoneTitle: get(data, 'contentfulD2CHomepage.supportedPhoneTitle'),
            supportedPhones: get(data, 'allContentfulDeviceDetailPage'),
            appleImage: get(data, 'contentfulD2CHomepage.applePhoneImage'),
            samsungImage: get(data, 'contentfulD2CHomepage.samsungPhoneImage'),
            oppoImage: get(data, 'contentfulD2CHomepage.oppoPhoneImage'),
            brands: '',
        }),
        data
    );

    return (
        <>
            <Seo
                title="Phone Insurance | Home"
                description="Asurion Mobile+ insures your phone in the event of accidental damage, loss or theft. Insure your phone based on the level of coverage you want."
                ogTitle="Asurion Mobile+ Smartphone Mobile Phone Insurance"
                ogDescription="Asurion Mobile+ insures your phone in the event of accidental damage, loss or theft. Insure your phone based on the level of coverage you want."
                canonical={process.env.GATSBY_DOMAIN_URL || '/'}
                image={OgImage}
                ogImage={OgImage}
            />
            {pageLoading && <SpinnerOverlay />}
            <Layout
                location={location}
                toastBarInfo={announcementMessage}
                subcomponent={
                    <>
                        {isMobile ? (
                            <>
                                <HeroMobile {...heroInfo} />
                                <Box ref={aboutRef} id="about-asurion" />
                                <Video {...videoInfo} />
                                <TabContentMobile {...tabContentInfo} />
                            </>
                        ) : (
                            <>
                                <Hero {...heroInfo} />
                                <Box ref={aboutRef} id="about-asurion" />
                                <Video {...videoInfo} />
                                <TabContent {...tabContentInfo} />
                            </>
                        )}
                        <Facts {...factsInfo} />
                        <FAQ {...faqInfo} />
                        <Reviews {...reviewsInfo} />
                    </>
                }
                bgColor=""
                partnerInfo={null}
            />
        </>
    );
}

export const pageQuery = graphql`
    query HomepageQueryV4 {
        contentfulD2CHomepage {
            announcementMessage {
                raw
            }
            banners {
                designVersion
                campaignId
                enabled
                dateStart
                dateEnd
                bannerBackgroundImage {
                    gatsbyImageData(placeholder: DOMINANT_COLOR, formats: [WEBP], quality: 100)
                }
                backgroundColorGradient
                baseTextColor
                heroImage {
                    gatsbyImageData(placeholder: NONE, formats: [WEBP], quality: 100)
                }
                heroImageTablet {
                    gatsbyImageData(placeholder: NONE, formats: [WEBP], quality: 100)
                }
                heroImageMobile {
                    gatsbyImageData(placeholder: NONE, formats: [WEBP], quality: 100)
                }
                line1 {
                    raw
                }
                line1Mobile {
                    raw
                }
                line2 {
                    raw
                }
                line2Mobile {
                    raw
                }
                line3 {
                    raw
                }
                line3Mobile {
                    raw
                }
                buttonText
                buttonLink
                line4 {
                    raw
                }
                line4Mobile {
                    raw
                }
            }
            ctaImage {
                gatsbyImageData(placeholder: NONE, formats: [WEBP], quality: 100)
            }
            ctaTitle
            ctaButtonText
            videoThumbnailImage {
                gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE)
            }
            videoImage {
                gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE)
                title
            }
            video {
                file {
                    url
                    fileName
                }
            }
            featuresTabText
            featuesTabHighlightTitle
            featuresTabHighlightDescription {
                raw
            }
            featuresImage {
                file {
                    url
                    fileName
                }
            }
            featuresCards {
                title
                description {
                    raw
                }
                thumbnail {
                    file {
                        url
                        fileName
                    }
                }
                thumbnailBackgroundColor
                featureText
            }
            featureMoreReasonsTitle
            featuresMoreReasonsDescription
            featuresMoreReasons {
                title
                description {
                    raw
                }
                thumbnail {
                    file {
                        url
                        fileName
                    }
                }
                thumbnailBackgroundColor
                featureText
            }
            planTabText
            plans {
                planName
                whoIsItFor
                bestFor
                price {
                    raw
                }
                screenRepairWithOemParts
                accidentalDamageLikeForLikeReplacement
                lostStolenLikeForLikeReplacement
                techSupport {
                    raw
                }
                securityAdvisor
                nationwideDelivery
                nationwideRepairNetwork
                onlineClaims
                newusedPhoneCoverage
                cancelAnytime
                flexiblePaymentsMonthlyyearly
                checkPlanPriceText
                readPolicyWordingText
                policyLink
                primaryColor
                secondaryColor
            }
            claimsTabText
            makeAClaimTitle
            makeAClaimDescription {
                raw
            }
            claimSteps {
                title
                thumbnail {
                    file {
                        url
                        fileName
                    }
                }
                thumbnailBackgroundColor
                featureText
            }
            applicationStepsTitle
            applicationStepsDescription
            applicationSteps {
                title
                thumbnail {
                    file {
                        url
                        fileName
                    }
                }
                thumbnailBackgroundColor
                tags
            }
            whyAsurionTabText
            whyAsurionHighlightTitle
            whyAsurionHighlightDescription {
                raw
            }
            whyAsurionHighlightImage {
                file {
                    url
                    fileName
                }
            }
            whyAsurionContent {
                anchorText
                contentTitle {
                    raw
                }
                contentDescription {
                    raw
                }
            }
            tabsDisclaimerText {
                raw
            }
            facts {
                line1
                line2
                line3
                line4
                linkUrl
                linkText
            }
            supportedPhoneTitle
            applePhoneImage {
                file {
                    url
                    fileName
                }
                gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE)
            }
            samsungPhoneImage {
                file {
                    url
                    fileName
                }
                gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE)
            }
            oppoPhoneImage {
                file {
                    url
                    fileName
                }
                gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE)
            }
            faqTitle
            faqDescription
            faqs {
                answer
                question
            }
            reviewTitle
            reviewDescription
            reviewPlatformRatings {
                name
                rating
            }
            reviewPlatformLogos {
                file {
                    url
                    fileName
                }
            }
            reviews {
                userName
                userRating
                userImage {
                    gatsbyImageData(placeholder: NONE, formats: [WEBP], quality: 100)
                }
                userLocation
                userReview {
                    raw
                }
            }
        }
        allContentfulDeviceDetailPage(
            filter: { node_locale: { eq: "en-US" } }
            sort: { fields: launchDate, order: DESC }
        ) {
            nodes {
                id
                title
                brandName
                slug
                launchDate
            }
        }
    }
`;

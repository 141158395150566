import React, { memo } from 'react';
import {
    Container,
    Box,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link as MuiLink,
    useMediaQuery,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme, styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import FaqTooltip from './common/FaqTooltip';
import parse from 'html-react-parser';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { redirectWithParams } from '../utils/Utils.js';
import FaqArrow from '../assets/faqArrow1.svg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface Query {
    question: string;
    answer: string;
}
export interface FAQInfo {
    title: string;
    // description: Document;
    description: string;
    queries: Query[];
}

const StyledAccordion = styled(Accordion)({
    borderRadius: '16px !important',
    color: '#671CA6',
    '&:nth-of-type(1n)': {
        border: '1px solid #D5D6DA',
    },
    '.Mui-expanded': {
        background: 'linear-gradient(106.14deg, #F3E9FB 0%, #E9D7F9 100%)',
        borderTopLeftRadius: '15px !important',
        borderTopRightRadius: '15px !important',
    },
});

const StyledAccordionDetails = styled(AccordionDetails)({
    // backgroundColor: '#F0F0F5',
    color: '#111111',
    background: 'linear-gradient(106.14deg, #F3E9FB 0%, #E9D7F9 100%)',
    borderBottomLeftRadius: '15px !important',
    borderBottomRightRadius: '15px !important',
});

const StyledExpandMoreIcon = styled(ExpandMoreIcon)({
    fill: '#8223D2',
});

function FAQ(faqInfo: FAQInfo) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const Bold = ({ children }) => (
        <Typography component="span" fontWeight="bold" fontSize={'2rem'}>
            {children}
        </Typography>
    );
    const Italic = ({ children }) => (
        <Typography component="span" fontStyle="italic" color="text.secondary" fontSize={'2rem'}>
            {children}
        </Typography>
    );
    const Text = ({ children }) => (
        <Typography className="" fontSize={'2rem'}>
            {children}
        </Typography>
    );

    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
            [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
            [MARKS.CODE]: (text: string) => parse(`${text}`),
        },
        renderNode: {
            // [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
            [BLOCKS.PARAGRAPH]: (node, children) =>
                node.content.some(
                    (childNode) =>
                        childNode.nodeType === `text` && childNode.marks.some((mark) => mark.type === MARKS.CODE)
                ) ? (
                    children
                ) : (
                    <Text>{children}</Text>
                ),
        },
    };

    const ReplaceWords = ({ text }) => {
        let updatedText = text;
        const isHereExist = text.indexOf('<here />') > -1;
        const isHerePrestigeExist = text.indexOf('<herePrestige />') > -1;
        const isReadMoreExist = text.indexOf('<Read more />') > -1;
        updatedText = updatedText.replace('<here />', '').replace('<Read more />', '').replace('<herePrestige />', '');
        return (
            <>
                {updatedText}
                {isHereExist && <a href="/resources/docs/asurionmobileplus-fsg-pds-lite.pdf">here</a>}
                {isHereExist && <span>.</span>}
                {isReadMoreExist && <FaqTooltip />}
                {isHerePrestigeExist && <a href="/resources/docs/asurionmobileplus-fsg-pds-prestige.pdf">here</a>}
                {isHerePrestigeExist && <span>.</span>}
            </>
        );
    };

    return (
        <Container maxWidth="xl" sx={{ my: 6, maxWidth: '1400px !important' }}>
            <Grid flexDirection="column" display="flex" justifyContent="left" textAlign="left" sx={{ mb: 2 }}>
                <Typography sx={{ fontSize: isMobile ? '1.5rem' : '2rem', color: '#671CA6', fontWeight: '700' }}>
                    {faqInfo.title}
                </Typography>
                <Box sx={{ '& > p': { fontSize: isMobile ? '1.125rem' : '1.125rem', color: '#671CA6' } }}>
                    {/* {renderRichText(faqInfo.description, options)} */}
                    <Typography>
                        {faqInfo.description}
                        {!isMobile ? (
                            <MuiLink
                                href={redirectWithParams(process.env.GATSBY_MOBILEPLUS_FAQS_URL)}
                                sx={{ textDecoration: 'none', float: 'right' }}
                                target="_blank"
                                id="btn-home-all-faqs"
                            >
                                See all FAQs <FaqArrow height={'16px'} />
                            </MuiLink>
                        ) : (
                            ''
                        )}
                    </Typography>
                </Box>
            </Grid>
            <Grid container sx={{ paddong: '0px' }}>
                {isMobile
                    ? faqInfo.queries.map((query, index) => (
                          <Grid sm={12} sx={{ my: 1 }} key={`question${index}`} item>
                              <StyledAccordion disableGutters elevation={0} key={`query-${index}`}>
                                  <AccordionSummary
                                      aria-controls="panel1d-content"
                                      expandIcon={<StyledExpandMoreIcon />}
                                      id="btn-home-faqs-question-ans"
                                  >
                                      <Typography
                                          fontSize={isMobile ? '1.125rem' : '1.5rem'}
                                          lineHeight={isMobile ? '1.5' : '1.5rem'}
                                          fontWeight={'700'}
                                      >
                                          {query.question}
                                      </Typography>
                                  </AccordionSummary>
                                  <StyledAccordionDetails>
                                      <Typography
                                          fontSize={isMobile ? '1.125rem' : '1.5rem'}
                                          lineHeight={isMobile ? '1.5' : '1.5'}
                                      >
                                          {ReplaceWords({ text: query.answer })}
                                      </Typography>
                                  </StyledAccordionDetails>
                              </StyledAccordion>
                          </Grid>
                      ))
                    : faqInfo.queries.map((query, index) => (
                          <Grid sm={6} sx={{ my: 2, pr: index % 2 == 0 ? 2 : 0 }} key={`question${index}`} item>
                              <StyledAccordion disableGutters elevation={0} key={`query-${index}`}>
                                  <AccordionSummary
                                      aria-controls="panel1d-content"
                                      expandIcon={<StyledExpandMoreIcon />}
                                      id="btn-home-faqs-question-ans"
                                  >
                                      <Typography
                                          fontSize={isMobile ? '1.125rem' : '1.125rem'}
                                          lineHeight={'1.5'}
                                          fontWeight={'700'}
                                      >
                                          {query.question}
                                      </Typography>
                                  </AccordionSummary>
                                  <StyledAccordionDetails>
                                      <Typography fontSize={isMobile ? '1.125rem' : '1.125rem'} lineHeight={'1.5'}>
                                          {ReplaceWords({ text: query.answer })}
                                      </Typography>
                                  </StyledAccordionDetails>
                              </StyledAccordion>
                          </Grid>
                      ))}

                {/* <Grid xs={12} display="flex" flexDirection="row" justifyContent="center" sx={{ mt: 2 }}>
                    <Typography textAlign="center" fontWeight="" fontSize={isMobile ? '1rem' : '1.25rem'}>
                        Have another question?{' '}
                        <MuiLink
                            href={redirectWithParams(process.env.GATSBY_MOBILEPLUS_FAQS_URL)}
                            sx={{ textDecorationColor: '#0000FF', color: '#0000FF' }}
                            target="_blank"
                            id="btn-home-all-faqs"
                        >
                            View all FAQs
                        </MuiLink>
                    </Typography>
                </Grid> */}
                {isMobile ? (
                    <MuiLink
                        href={redirectWithParams(process.env.GATSBY_MOBILEPLUS_FAQS_URL)}
                        sx={{ textDecoration: 'none', float: 'right', fontSize: '18px', fontWeight: '700' }}
                        target="_blank"
                        id="btn-home-all-faqs"
                        pt={2}
                    >
                        See all FAQs <FaqArrow height={'16px'} />
                    </MuiLink>
                ) : (
                    ''
                )}
            </Grid>
        </Container>
    );
}

export default memo(FAQ);
